@import "../../variable.scss";

.cms_sc {
    * {
        color: $c_white;
    }

    h1 {
        font-size: 36px;
        font-weight: 600;
        margin-bottom: 30px;

        @media (max-width: 1399.98px) {
            font-size: 30px;
        }

        @media (max-width: 1199.98px) {
            font-size: 28px;
        }

        @media (max-width: 991.98px) {
            font-size: 25px;
        }

        @media (max-width: 600px) {
            font-size: 22px;
        }
    }

    h2 {
        font-size: 18px;
        font-weight: 700;
        margin: 30px 0 15px
    }

    p,
    li {
        font-size: 14px;

        &:not(:last-child) {
            margin-bottom: 15px;
        }
    }

    ul {
        margin-left: 20px;

        &:not(:last-child) {
            margin-bottom: 15px;
        }

        li {
            list-style: disc;

            &:not(:last-child) {
                margin-bottom: 5px;
            }
        }
    }
}

.contact_sc {
    :is(.left-side, .right-side) {
        width: 50%;

        @media (max-width: 991.98px) {
            width: 100%;
        }
    }

    .conta_iner {
        >.gap_p {
            --gap_x: 50px;

            @media (max-width: 991.98px) {
                --gap_x: 15px;
                --gap_y: 50px;
                flex-direction: column;
            }
        }
    }

    .left-side {
        * {
            color: $c_white;
        }

        h1 {
            font-size: 20px;
            font-weight: 700;
            margin-bottom: 15px;
        }

        p {
            padding: 10px;
            border: 1px solid #ffffff2b;
            border-radius: 4px;
        }

        h2 {
            font-size: 18px;
            font-weight: 700;
            margin: 30px 0 15px;

            @media (max-width: 991.98px) {
                font-size: 16px;
                margin: 30px 0 10px
            }
        }

        p {
            font-size: 14px;

            @media (max-width: 600px) {
                br {
                    display: none;
                }
            }

            &:not(:last-child) {
                margin-bottom: 0;
            }

            a {
                &:hover {
                    color: $c_secondary;
                    text-decoration: underline;
                }
            }
        }
    }

    .right-side {
        .form_control {
            @media (max-width: 991.98px) {
                margin-bottom: 15px;
            }
        }

        .form_btn {
            @media (max-width: 991.98px) {
                margin-top: 30px;
                text-align: center;
            }

            .btn {
                width: 100%;

                @media (max-width: 991.98px) {
                    width: auto;
                }
            }
        }
    }
}

.about_page {
    .head_mb {
        margin-bottom: 60px;
    }

    h2 {
        font-weight: 400;
        text-align: center;
        font-family: $f_wains;
        padding-bottom: 10px;

        span {
            position: relative;
            display: inline-block;

            &::before {
                content: "";
                position: absolute;
                height: 3px;
                width: 100%;
                left: 0;
                top: calc(100% + 10px);
                transform: translateY(-100%);
                background-color: $c_secondary;
                border-radius: 3px;
            }
        }

        +p {
            color: $c_white;
            font-size: 24px;
            font-weight: 300;
            margin-top: 30px;
            max-width: 1100px;
            margin-inline: auto;
            line-height: 1.4;

            @media (max-width: 1199.98px) {
                font-size: 20px;
            }

            @media (max-width: 991.98px) {
                font-size: 16px;
            }

            @media (max-width: 767.98px) {
                font-size: 14px;
            }
        }
    }
}

.about_page_sc {
    text-align: center;

    h1 {
        font-size: 36px;
        color: $c_white;
        font-weight: 700;
        margin-bottom: 25px;

        @media (max-width: 1199.98px) {
            font-size: 30px;
        }

        @media (max-width: 991.98px) {
            font-size: 25px;
        }

        @media (max-width: 767.98px) {
            font-size: 22px;
        }
    }

    p {
        color: $c_white;
        font-size: 24px;
        font-weight: 300;
        line-height: 1.4;

        @media (max-width: 1199.98px) {
            font-size: 20px;
        }

        @media (max-width: 991.98px) {
            font-size: 16px;

            .d_block {
                display: inline;
            }
        }
    }

    figure {
        margin-top: 50px;
        text-align: center;
        position: relative;
        z-index: 1;

        &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            width: 408px;
            height: 408px;
            opacity: 0.5;
            transform: translate(-50%, -50%);
            background: $c_secondary;
            border-radius: 50%;
            filter: blur(212px);
            z-index: -1;

            @media (max-width: 600px) {
                display: none;
            }

        }
    }

    img {
        @media (max-width: 1199.98px) {
            max-width: 600px;
        }

        @media (max-width: 991.98px) {
            max-width: 500px;
        }

        @media (max-width: 600px) {
            width: 90%;
            max-width: 100%;
        }
    }
}

.about_story_sc {
    .gap_p {
        align-items: center;

        @media (max-width: 991.98px) {
            flex-direction: column;
        }
    }

    :is(.left_s, .right_s) {
        width: 50%;

        @media (max-width: 991.98px) {
            width: 100%;
            text-align: center;
        }
    }

    .left_s {
        figure {
            width: 450px;
            height: 465px;
            margin: 40px 0 0 40px;
            position: relative;

            @media (max-width: 1199.98px) {
                width: 380px;
                height: 430px;

            }







            @media (max-width: 991.98px) {
                margin: 0 auto;
            }

            @media (max-width: 600px) {
                width: 100%;
                height: auto;

            }

            &::before {
                content: "";
                position: absolute;
                top: -40px;
                left: -40px;
                width: 100%;
                height: 100%;
                border: 5px solid $c_secondary;
                background-color: #252525;
                z-index: 2;

                @media (max-width: 600px) {
                    display: none;
                }

            }

            &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 408px;
                height: 408px;
                opacity: 0.6;
                transform: translate(-50%, 0);
                background: $c_secondary;
                border-radius: 50%;
                filter: blur(212px);
                z-index: 1;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
                position: relative;
                z-index: 3;
            }
        }
    }

    .right_s {
        p {
            color: #fff;
            font-size: 18px;

            @media (max-width: 991.98px) {
                font-size: 16px;
            }

            @media (max-width: 767.98px) {
                font-size: 14px;
            }
        }
    }
}

.about_team_sc {
    .head_mb {
        margin-bottom: 60px;
    }

    .team_grid {
        max-width: 900px;
        margin-inline: auto;

        .gap_m {
            --gap_x: 50px;
            align-items: flex-start;

            @media (max-width: 991.98px) {
                --gap_x: 30px;
            }

            @media (max-width: 600px) {
                --gap_x: 15px;
                --gap_y: 30px;
            }
        }
    }

    .single_team {
        width: calc(100% / 3 - var(--gap_x));
        text-align: center;

        @media (max-width: 767.98px) {
            width: calc(49% - var(--gap_x));
        }

        @media (max-width: 600px) {
            width: calc(100% - var(--gap_x));
        }


        &:not(:nth-child(3n + 2)) {
            padding-top: 50px;

            @media (max-width: 991.98px) {
                padding-top: 0px;
            }
        }

        figure {
            margin-bottom: 25px;
            display: inline-flex;
            justify-content: center;
            height: 280px;
            width: 260px;
            overflow: hidden;
            background: #0000003b;
            border-radius: 20%;

            @media (max-width: 991.98px) {
                height: 250px;
                width: 100%;
                margin-bottom: 15px;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;

                @media (max-width: 991.98px) {
                    object-fit: cover;
                    object-position: top center;
                }
            }
        }

        p {
            color: $c_white;
            font-size: 24px;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 10px;
            line-height: 1;

            @media (max-width: 1199.98px) {
                font-size: 20px;
            }

            @media (max-width: 991.98px) {
                font-size: 16px;

                span {
                    font-size: 14px;
                }
            }
        }

        &:nth-child(3n+3) {
            &~.single_team {
                width: calc(25% - var(--gap_x));
                padding-top: 0;

                @media (max-width: 991.98px) {
                    width: calc(25% - var(--gap_x));
                }

                @media (max-width: 600px) {
                    width: calc(50% - var(--gap_x));
                }

                figure {
                    width: 100%;
                    height: auto;
                    aspect-ratio: 1;
                    border-radius: 50%;

                    @media (max-width: 991.98px) {
                        width: 85%;
                    }

                    @media (max-width: 767.98px) {
                        width: 77%;
                    }

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: top;
                    }
                }

                p {
                    font-size: 18px;

                    @media (max-width: 991.98px) {
                        font-size: 16px;
                    }
                }
            }
        }
    }
}

@media (max-width: 600px) {
    .contact_sc .gap_p {
        flex-direction: column;
    }

    .contact_sc .w_50 {
        width: 100%;
    }
}