@import "./variable.scss";

@font-face {
  font-family: 'Wainscoted';
  src: url('../public/fonts/Wainscoted.eot');
  src: url('../public/fonts/Wainscoted.eot?#iefix') format('embedded-opentype'),
    url('../public/fonts/Wainscoted.woff2') format('woff2'),
    url('../public/fonts/Wainscoted.woff') format('woff'),
    url('../public/fonts/Wainscoted.ttf') format('truetype'),
    url('../public/fonts/Wainscoted.svg#Wainscoted') format('svg');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Bw Modelica';
  src: url('../public/fonts/BwModelicaCyrillicDEMO-Hairline.eot');
  src: local('Bw Modelica Cyrillic DEMO Hairline'), local('BwModelicaCyrillicDEMO-Hairline'),
    url('../public/fonts/BwModelicaCyrillicDEMO-Hairline.eot?#iefix') format('embedded-opentype'),
    url('../public/fonts/BwModelicaCyrillicDEMO-Hairline.woff2') format('woff2'),
    url('../public/fonts/BwModelicaCyrillicDEMO-Hairline.woff') format('woff'),
    url('../public/fonts/BwModelicaCyrillicDEMO-Hairline.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Bw Modelica';
  src: url('../public/fonts/BwModelicaCyrillicDEMO-HairlineItalic.eot');
  src: local('Bw Modelica Cyrillic DEMO Hairline Italic'), local('BwModelicaCyrillicDEMO-HairlineItalic'),
    url('../public/fonts/BwModelicaCyrillicDEMO-HairlineItalic.eot?#iefix') format('embedded-opentype'),
    url('../public/fonts/BwModelicaCyrillicDEMO-HairlineItalic.woff2') format('woff2'),
    url('../public/fonts/BwModelicaCyrillicDEMO-HairlineItalic.woff') format('woff'),
    url('../public/fonts/BwModelicaCyrillicDEMO-HairlineItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Bw Modelica';
  src: url('../public/fonts/BwModelicaCyrillicDEMO-Thin.eot');
  src: local('Bw Modelica Cyrillic DEMO Thin'), local('BwModelicaCyrillicDEMO-Thin'),
    url('../public/fonts/BwModelicaCyrillicDEMO-Thin.eot?#iefix') format('embedded-opentype'),
    url('../public/fonts/BwModelicaCyrillicDEMO-Thin.woff2') format('woff2'),
    url('../public/fonts/BwModelicaCyrillicDEMO-Thin.woff') format('woff'),
    url('../public/fonts/BwModelicaCyrillicDEMO-Thin.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Bw Modelica';
  src: url('../public/fonts/BwModelicaCyrillicDEMO-ThinItalic.eot');
  src: local('Bw Modelica Cyrillic DEMO Thin Italic'), local('BwModelicaCyrillicDEMO-ThinItalic'),
    url('../public/fonts/BwModelicaCyrillicDEMO-ThinItalic.eot?#iefix') format('embedded-opentype'),
    url('../public/fonts/BwModelicaCyrillicDEMO-ThinItalic.woff2') format('woff2'),
    url('../public/fonts/BwModelicaCyrillicDEMO-ThinItalic.woff') format('woff'),
    url('../public/fonts/BwModelicaCyrillicDEMO-ThinItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Bw Modelica';
  src: url('../public/fonts/BwModelicaCyrillicDEMO-Light.eot');
  src: local('Bw Modelica Cyrillic DEMO Light'), local('BwModelicaCyrillicDEMO-Light'),
    url('../public/fonts/BwModelicaCyrillicDEMO-Light.eot?#iefix') format('embedded-opentype'),
    url('../public/fonts/BwModelicaCyrillicDEMO-Light.woff2') format('woff2'),
    url('../public/fonts/BwModelicaCyrillicDEMO-Light.woff') format('woff'),
    url('../public/fonts/BwModelicaCyrillicDEMO-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Bw Modelica';
  src: url('../public/fonts/BwModelicaCyrillicDEMO-LightItalic.eot');
  src: local('Bw Modelica Cyrillic DEMO Light Italic'), local('BwModelicaCyrillicDEMO-LightItalic'),
    url('../public/fonts/BwModelicaCyrillicDEMO-LightItalic.eot?#iefix') format('embedded-opentype'),
    url('../public/fonts/BwModelicaCyrillicDEMO-LightItalic.woff2') format('woff2'),
    url('../public/fonts/BwModelicaCyrillicDEMO-LightItalic.woff') format('woff'),
    url('../public/fonts/BwModelicaCyrillicDEMO-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Bw Modelica';
  src: url('../public/fonts/BwModelicaCyrillicDEMO-Regular.eot');
  src: local('Bw Modelica Cyrillic DEMO Regular'), local('BwModelicaCyrillicDEMO-Regular'),
    url('../public/fonts/BwModelicaCyrillicDEMO-Regular.eot?#iefix') format('embedded-opentype'),
    url('../public/fonts/BwModelicaCyrillicDEMO-Regular.woff2') format('woff2'),
    url('../public/fonts/BwModelicaCyrillicDEMO-Regular.woff') format('woff'),
    url('../public/fonts/BwModelicaCyrillicDEMO-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Bw Modelica';
  src: url('../public/fonts/BwModelicaCyrillicDEMO-RegularItalic.eot');
  src: local('Bw Modelica Cyrillic DEMO Regular Italic'), local('BwModelicaCyrillicDEMO-RegularItalic'),
    url('../public/fonts/BwModelicaCyrillicDEMO-RegularItalic.eot?#iefix') format('embedded-opentype'),
    url('../public/fonts/BwModelicaCyrillicDEMO-RegularItalic.woff2') format('woff2'),
    url('../public/fonts/BwModelicaCyrillicDEMO-RegularItalic.woff') format('woff'),
    url('../public/fonts/BwModelicaCyrillicDEMO-RegularItalic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Bw Modelica';
  src: url('../public/fonts/BwModelicaCyrillicDEMO-Medium.eot');
  src: local('Bw Modelica Cyrillic DEMO Medium'), local('BwModelicaCyrillicDEMO-Medium'),
    url('../public/fonts/BwModelicaCyrillicDEMO-Medium.eot?#iefix') format('embedded-opentype'),
    url('../public/fonts/BwModelicaCyrillicDEMO-Medium.woff2') format('woff2'),
    url('../public/fonts/BwModelicaCyrillicDEMO-Medium.woff') format('woff'),
    url('../public/fonts/BwModelicaCyrillicDEMO-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Bw Modelica';
  src: url('../public/fonts/BwModelicaCyrillicDEMO-MediumItalic.eot');
  src: local('Bw Modelica Cyrillic DEMO Medium Italic'), local('BwModelicaCyrillicDEMO-MediumItalic'),
    url('../public/fonts/BwModelicaCyrillicDEMO-MediumItalic.eot?#iefix') format('embedded-opentype'),
    url('../public/fonts/BwModelicaCyrillicDEMO-MediumItalic.woff2') format('woff2'),
    url('../public/fonts/BwModelicaCyrillicDEMO-MediumItalic.woff') format('woff'),
    url('../public/fonts/BwModelicaCyrillicDEMO-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Bw Modelica';
  src: url('../public/fonts/BwModelicaCyrillicDEMO-Bold.eot');
  src: local('Bw Modelica Cyrillic DEMO Bold'), local('BwModelicaCyrillicDEMO-Bold'),
    url('../public/fonts/BwModelicaCyrillicDEMO-Bold.eot?#iefix') format('embedded-opentype'),
    url('../public/fonts/BwModelicaCyrillicDEMO-Bold.woff2') format('woff2'),
    url('../public/fonts/BwModelicaCyrillicDEMO-Bold.woff') format('woff'),
    url('../public/fonts/BwModelicaCyrillicDEMO-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Bw Modelica';
  src: url('../public/fonts/BwModelicaCyrillicDEMO-BoldItalic.eot');
  src: local('Bw Modelica Cyrillic DEMO Bold Italic'), local('BwModelicaCyrillicDEMO-BoldItalic'),
    url('../public/fonts/BwModelicaCyrillicDEMO-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('../public/fonts/BwModelicaCyrillicDEMO-BoldItalic.woff2') format('woff2'),
    url('../public/fonts/BwModelicaCyrillicDEMO-BoldItalic.woff') format('woff'),
    url('../public/fonts/BwModelicaCyrillicDEMO-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Bw Modelica';
  src: url('../public/fonts/BwModelicaCyrillicDEMO-ExtraBold.eot');
  src: local('Bw Modelica Cyrillic DEMO ExtraBold'), local('BwModelicaCyrillicDEMO-ExtraBold'),
    url('../public/fonts/BwModelicaCyrillicDEMO-ExtraBold.eot?#iefix') format('embedded-opentype'),
    url('../public/fonts/BwModelicaCyrillicDEMO-ExtraBold.woff2') format('woff2'),
    url('../public/fonts/BwModelicaCyrillicDEMO-ExtraBold.woff') format('woff'),
    url('../public/fonts/BwModelicaCyrillicDEMO-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Bw Modelica';
  src: url('../public/fonts/BwModelicaCyrillicDEMO-ExtraBoldItalic.eot');
  src: local('Bw Modelica Cyrillic DEMO ExtraBold Italic'), local('BwModelicaCyrillicDEMO-ExtraBoldItalic'),
    url('../public/fonts/BwModelicaCyrillicDEMO-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
    url('../public/fonts/BwModelicaCyrillicDEMO-ExtraBoldItalic.woff2') format('woff2'),
    url('../public/fonts/BwModelicaCyrillicDEMO-ExtraBoldItalic.woff') format('woff'),
    url('../public/fonts/BwModelicaCyrillicDEMO-ExtraBoldItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Bw Modelica';
  src: url('../public/fonts/BwModelicaCyrillicDEMO-Black.eot');
  src: local('Bw Modelica Cyrillic DEMO Black'), local('BwModelicaCyrillicDEMO-Black'),
    url('../public/fonts/BwModelicaCyrillicDEMO-Black.eot?#iefix') format('embedded-opentype'),
    url('../public/fonts/BwModelicaCyrillicDEMO-Black.woff2') format('woff2'),
    url('../public/fonts/BwModelicaCyrillicDEMO-Black.woff') format('woff'),
    url('../public/fonts/BwModelicaCyrillicDEMO-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Bw Modelica';
  src: url('../public/fonts/BwModelicaCyrillicDEMO-BlackItalic.eot');
  src: local('Bw Modelica Cyrillic DEMO Black Italic'), local('BwModelicaCyrillicDEMO-BlackItalic'),
    url('../public/fonts/BwModelicaCyrillicDEMO-BlackItalic.eot?#iefix') format('embedded-opentype'),
    url('../public/fonts/BwModelicaCyrillicDEMO-BlackItalic.woff2') format('woff2'),
    url('../public/fonts/BwModelicaCyrillicDEMO-BlackItalic.woff') format('woff'),
    url('../public/fonts/BwModelicaCyrillicDEMO-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

// reset css start here
* {
  outline: none !important;
  margin: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: $c_primary;
  color: $c_body;
  font-family: $f_body;
}

a,
a:hover {
  transition: $transition;
}

b,
strong {
  font-weight: 600;
}

a {
  text-decoration: none;
  transition: $transition;
}

li {
  list-style: none;
}

:is(h1, h2, h3, h4, h5, h6) {
  color: $c_heading;
  margin-bottom: 0;
  font-family: $f_body;
}

p {
  margin-bottom: 0;
}

h1,
.hd_1 :where(h1, h2, h3, h4, h5, h6) {
  font-size: 56px;
  font-weight: 700;
  line-height: 1.2;

  @media screen and (max-width: 1399.98px) {
    font-size: 45px;
  }

  @media screen and (max-width: 1199.98px) {
    font-size: 42px;
  }

  @media screen and (max-width: 991.98px) {
    font-size: 40px;
  }

  @media screen and (max-width: 767.98px) {
    font-size: 36px;
  }
}

h2,
.hd_2 :where(h1, h2, h3, h4, h5, h6) {
  font-size: 36px;
  font-weight: 700;
  line-height: 1.2;

  @media screen and (max-width: 1399.98px) {
    font-size: 36px;
  }

  @media screen and (max-width: 1199.98px) {
    font-size: 32px;
  }

  @media screen and (max-width: 767.98px) {
    font-size: 28px;
  }
}

h3,
.hd_3 :where(h1, h2, h3, h4, h5, h6) {
  font-size: 40px;
  font-weight: 700;
  line-height: 1.2;

  @media screen and (max-width: 1399.98px) {
    font-size: 36px;
  }

  @media screen and (max-width: 1199.98px) {
    font-size: 32px;
  }

  @media screen and (max-width: 991.98px) {
    font-size: 30px;
  }

  @media screen and (max-width: 991.98px) {
    font-size: 25px;
  }
}

h4,
.hd_4 :where(h1, h2, h3, h4, h5, h6) {
  font-size: 34px;
  font-weight: 700;
  line-height: 1.2;
}

h5,
.hd_5 :where(h1, h2, h3, h4, h5, h6) {
  font-size: 30px;
  font-weight: 700;
  line-height: 1.2;

  @media screen and (max-width: 1399.98px) {
    font-size: 28px;
  }
}

h6,
.hd_6 :where(h1, h2, h3, h4, h5, h6) {
  font-size: 24px;
  font-weight: 700;
  line-height: 1.2;

  @media screen and (max-width: 1199.98px) {
    font-size: 22px;
  }
}

ul {
  padding: 0;
}

body,
p,
li {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.6;
  word-wrap: break-word;
  // word-break: break-all;
  //   color: $c_text;
  font-family: $f_body;

  @media screen and (max-width: 767.98px) {
    font-size: 14px;
  }
}

.d_block {
  display: block;
}

//phone Input
.react-tel-input .form-control:focus {
  box-shadow: none !important;
  background-color: transparent;
  // border: 1px solid $c_white !important;
}

.react-tel-input .selected-flag:focus:before,
.react-tel-input .selected-flag.open:before {
  box-shadow: none !important;
  border: 1px solid transparent !important;
}

.phone_drpdwn {
  li {
    span {
      color: black;

      &:hover {
        color: black;
      }
    }

    input {
      height: 42px;
    }
  }
}

// phone input

.btn,
.btn.MuiButton-root {
  height: auto;
  min-height: 56px;
  min-width: 205px;
  font-size: 18px;
  font-weight: 500;
  padding: 5px 25px;
  border-radius: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
  text-indent: 0px;
  position: relative;
  letter-spacing: 0px;
  text-transform: capitalize;
  box-shadow: none !important;
  transition: $transition;
  border: 1px solid transparent;
  font-family: $f_body;
  position: relative;
  overflow: hidden;
  z-index: 1;

  @media screen and (max-width: 767.98px) {
    min-height: 50px;
    min-width: 200px;
    font-size: 16px;
    padding: 5px 20px;
  }

  &::before {
    content: "";
    top: 0;
    width: 100%;
    position: absolute;
    bottom: 0;
    z-index: -1;
    animation: slide 2s infinite;
    background: linear-gradient(to right,
        rgba(64, 235, 241, 0) 0%,
        rgba(255, 254, 253, 0.56) 50%,
        rgba(255, 254, 253, 0.28) 69%,
        rgba(64, 235, 241, 0) 100%);
  }

  &.btn_primary {
    color: $c_white;
    background-color: #252525;
    border-color: #252525;

    &:is(:hover, :focus) {
      color: #252525;
      background-color: $c_secondary;
      border-color: $c_secondary;
    }

    &.br {
      color: $c_white;
      background-color: $c_primary;
      border-color: #9d9d9d;

      &:is(:hover, :focus) {
        color: $c_secondary;
        background-color: $c_primary;
        border-color: $c_secondary;
      }
    }
  }

  &.btn_secondary {
    color: $c_primary;
    background-color: $c_secondary;
    border-color: $c_secondary;
    // min-height: 40px;
    // min-width: auto;

    &:is(:hover, :focus) {
      color: $c_primary;
      background-color: $c_white;
      border-color: $c_secondary;
    }
  }
}

.btn {
  &_link {
    font-size: 16px;
    font-weight: 500;
    color: $c_secondary;
    transition: $transition;

    &:hover {
      text-decoration: underline;
      text-underline-offset: 3px;
    }
  }

  &_rounded {
    width: 200px;
    min-width: 200px;
    height: 200px;
    padding: 20px;
    position: relative;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $c_white;
    font-size: 19px;
    font-weight: 400;
    text-transform: uppercase;
    z-index: 1;
    cursor: pointer;
    transition: $transition;

    @media (max-width: 1199.98px) {
      width: 150px;
      min-width: 150px;
      height: 150px;
      font-size: 16px;
    }

    @media (max-width: 600px) {
      font-size: 14px;
    }

    &::after {
      content: "";
      position: absolute;
      inset: 0;
      border: 1px dashed #27262B;
      border-radius: 50%;
      pointer-events: none;
      animation: rotating 1s linear infinite;
    }

    &::before {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      width: calc(100% - 40px);
      height: calc(100% - 40px);
      background-color: #27262B;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      z-index: -1;
      transition: $transition;
    }

    svg {
      font-size: 50px;

      @media (max-width: 600px) {
        font-size: 40px;
      }
    }

    &:hover {
      color: $c_secondary;

      &::before {
        transform: translate(-50%, -50%) scale(1.1);
      }
    }
  }
}

@keyframes slide {
  0% {
    left: -100%;
  }

  100% {
    left: 100%;
  }
}

@keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

.d_flx {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.align_top {
  align-items: unset;
}

.bg_dark {
  background-color: $c_heading;
}

.c_primary {
  color: $c_primary;
}

.clr_theme {
  color: $c_primary;
  font-weight: 600;
}

.wt_reg {
  font-weight: 400;
}

.ut_spc,
.u_spc {
  padding-top: 80px;

  @media screen and (max-width: 1199.98px) {
    padding-top: 60px;
  }

  @media screen and (max-width: 767.98px) {
    padding-top: 50px;
  }
}

.ub_spc,
.u_spc {
  padding-bottom: 80px;

  @media screen and (max-width: 1199.98px) {
    padding-bottom: 60px;
  }

  @media screen and (max-width: 767.98px) {
    padding-bottom: 50px;
  }
}

.uht_spc,
.uh_spc {
  padding-top: 40px;
}

.uhb_spc,
.uh_spc {
  padding-bottom: 40px;
}

@media (max-width: 1199.98px) {
  .newEra_sc .gap_p {
    --gap_y: 30px;
    flex-direction: column;
  }
}

.gap_p,
.gap_m {
  --gap_x: 30px;
  --gap_y: 30px;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--gap_y) * -1);
  margin-left: calc(var(--gap_x) * -1);

  @media (max-width: 767.98px) {
    --gap_x: 15px;
    --gap_y: 15px;
  }

  >* {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    margin-top: var(--gap_y);
  }

  &.gap_y_0 {
    --gap_y: 0px;
  }

  &.gap_x_0 {
    --gap_x: 0px;
  }
}

.gap_p>* {
  padding-left: var(--gap_x);
}

.gap_m>* {
  margin-left: var(--gap_x);
}

.aic {
  align-items: center;
}

.mrg_tbbt {
  margin: 20px 0;
}

.uhb_spc,
.uh_spc {
  padding-bottom: 30px;

  @media screen and (max-width: 1199.98px) {
    padding-bottom: 20px;
  }
}

.clr_white :where(h1, h2, h3, h4, h5, h6, p, a, li) {
  color: $c_white;
}

.line {
  border: none;
  border-bottom: 1px solid #fff;
  margin: 10px 0;
}

.w_48 {
  width: 48%;

  @media screen and (max-width: 991.98px) {
    width: 100%;
  }
}

.gry_txt {
  color: $grey;
  margin: 15px 0;
}

.text_center {
  text-align: center;
}

.max_60 {
  max-width: 60%;
  margin: 0 auto;
}

.wd_60 {
  max-width: 60%;
}

.w {
  &_50 {
    width: 50%;
  }

  &_100 {
    width: 100%;
  }
}

:is(li.MuiButtonBase-root) {
  min-height: 1px;
  font-family: $f_body;
}

.form {
  &_control {
    margin-bottom: 24px;

    @media screen and (max-width: 767.98px) {
      margin-bottom: 15px;
    }

    .gap_p {
      --gap_x: 24px;
      --gap_y: 24px;
    }

    h5 {
      color: $c_white;
      font-size: 16px;
      font-weight: 700;
      padding-bottom: 30px;
    }

    label {
      color: $grey;
      font-size: 14px;
      font-weight: 500;
      display: block;

      &:not(:last-child) {
        margin-bottom: 5px;
      }

      h6 {
        color: $c_white;
        font-size: 16px;
        font-weight: 700;
        padding-bottom: 5px;
      }
    }

    :is(input, select, .MuiSelect-select, textarea) {
      width: 100%;
      color: $c_white;
      font-size: 14px;
      font-weight: 400;
      border-radius: 8px !important;
      border: 1px solid #EEF2F6;
      background: $c_darkgray;
      outline: none;
      box-shadow: none;
      box-sizing: border-box;
      font-family: $f_body;
      transition: $transition;

      @media screen and (max-width: 767.98px) {
        border-radius: 5px !important;
      }

      &:focus {
        border-color: $c_secondary;
      }

      &::placeholder {
        color: #9aa4b2;
        opacity: 1;
      }

      &:disabled {
        color: $c_white;
        cursor: not-allowed;

        -webkit-text-fill-color: $c_white;
        opacity: 0.5;
      }
    }

    .search_field {
      input {
        background-image: url("../public/images/icon_search.svg");
        background-position: right 20px center;
        background-size: 25px;
        background-repeat: no-repeat;
        padding-right: 65px !important;

        @media screen and (max-width: 767.98px) {
          background-size: 20px;
          padding-right: 55px !important;
        }
      }
    }

    input {
      height: 56px;
      padding: 5px 15px;

      @media screen and (max-width: 767.98px) {
        height: 50px;
        padding: 5px 10px;
      }
    }

    select {
      height: 56px;
      padding: 0 60px 0 15px;

      @media screen and (max-width: 767.98px) {
        height: 50px;
        padding: 0 40px 0 10px;
      }
    }

    .MuiSelect-select {
      height: 56px !important;
      padding: 0 60px 0 15px !important;
      font-family: $f_body;
      display: flex;
      align-items: center;
      line-height: 1;

      @media screen and (max-width: 767.98px) {
        height: 50px !important;
        padding: 0 40px 0 10px !important;
      }

      &[aria-expanded="true"] {
        border-color: $c_secondary;
      }

      ~fieldset {
        display: none;
      }

      ~.MuiSvgIcon-root {
        right: 10px;
        font-size: 35px;
        color: $c_secondary;

        @media screen and (max-width: 767.98px) {
          right: 7px;
          font-size: 25px;
        }
      }
    }

    textarea {
      height: 100px;
      padding: 10px 15px;
    }

    .MuiFormGroup-root {
      &.flex {
        flex-direction: row;
        gap: 20px 50px;

        label {
          margin-bottom: 0;
        }
      }
    }

    .label_checkbox {
      display: inline-flex;
      align-items: center;
      gap: 10px;
      width: auto;
      cursor: pointer;
      margin: 0;

      &:not(:last-child) {
        margin: 0 0 20px;

        &.mb_0 {
          margin: var(--gap_y) 0 0;
        }
      }

      .MuiRadio-root {
        padding: 0;

        .MuiSvgIcon-root {
          fill: $c_darkgray;

          &:nth-child(2) {
            transform: scale(1.7);
          }
        }

        &.Mui-checked {
          color: $c_secondary;

          .MuiSvgIcon-root {
            fill: currentColor;

            &:nth-child(2) {
              transform: scale(1);
            }
          }
        }
      }

      input {
        accent-color: $c_secondary;

        &[type="checkbox"] {
          width: 16px;
          height: 16px;
        }

        &[type="radio"] {
          width: 20px;
          height: 20px;
        }
      }

      >span {
        font-size: 14px;
        font-weight: 500;
        color: $c_white;
        font-family: $f_body;
      }
    }

    div.label_checkbox {
      label {
        color: $c_white;
        margin: 0;
        display: inline-flex;
        align-items: center;
        gap: 5px;

        >span {
          font-size: 14px;
          font-weight: 500;
          color: $c_white;
          font-family: $f_body;
        }
      }

      .MuiCheckbox-root {
        padding: 0;
        position: relative;
        top: 1px;

        &.Mui-checked {
          color: $c_secondary;
        }
      }
    }

    .upload_image {
      display: inline-flex;
      width: auto;
      cursor: pointer;

      input[type="file"] {
        position: absolute;
        inset: 0;
        padding: 0;
        height: 100%;
        width: 100%;
        opacity: 0;
        cursor: pointer;
        text-indent: -1000px;
      }
    }

    span:not([class]) {
      font-size: 14px;
      font-weight: 500;
      color: $c_white;
      width: auto;
      cursor: pointer;

      &:hover {
        color: $c_secondary;
      }
    }

    .MuiInputBase-hiddenLabel {
      .MuiOutlinedInput-notchedOutline {
        display: none;
      }
    }

    .MuiNativeSelect-root {

      &::after,
      &::before {
        display: none;
      }

      select {
        padding-right: 50px !important;

        option {
          color: $c_black;
        }

        &:focus {
          border-radius: 8px;
          background: $c_darkgray;
        }
      }

      .MuiNativeSelect-icon {
        right: 10px;
        font-size: 35px;
        color: $c_secondary;
      }
    }
  }

  &_btn {
    margin-top: 40px;
  }
}

.m_0 {
  margin: 0;
}

.mt_0 {
  margin-top: 0;
}

.mtb_60 {
  margin: 60px 0;
}

.mb_40 {
  margin-bottom: 40px;
}

.mb_60 {
  margin-bottom: 60px;

  @media screen and (max-width: 1199.98px) {
    margin-bottom: 40px;
  }
}

main {
  padding-top: 121px;
  min-height: calc(100vh - 469.14px);

  @media screen and (max-width: 1399.98px) {
    padding-top: 96px;
    min-height: calc(100vh - 430.95px);
  }

  @media screen and (max-width: 767.98px) {
    padding-top: 81px;
    min-height: 1px;
  }
}

.conta_iner {
  max-width: 1170px;
  margin: 0 auto;

  @media screen and (max-width: 1399.98px) {
    width: 90%;
  }

  @media screen and (max-width: 767.98px) {
    width: 100%;
    padding-inline: 15px;
  }
}

.side_container {
  width: calc(100% - (100% - 90%) / 2);
  max-width: calc(100% - (100% - 1170px) / 2);
  margin: 0 auto;

  @media (max-width: 1199.98px) {
    .gap_p {
      justify-content: center;
    }
  }

  @media screen and (max-width: 767.98px) {
    width: 100%;
    padding-inline: 15px;
  }
}

body:has(.home_page) {
  background-color: #131313;
}

// reset css end here

@import '../src/layout/Layout.scss';

.text_field p,
.err_msg {
  color: #ff5b5b !important;
  font-size: 14px !important;
  margin: 3px 0 0;
  font-weight: 500 !important;
  letter-spacing: 0;
  font-family: $f_body;
  line-height: normal;
}

.eye_btn {
  position: absolute;
  height: 56px !important;
  max-height: 56px !important;
  width: 56px;
  margin: 0;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 767.98px) {
    height: 50px !important;
    max-height: 50px !important;
    width: 50px;
  }

  button {
    height: 80%;
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0 auto;

    svg {
      height: 20px;
      width: 20px;
      color: #9aa4b2;
    }
  }
}

.text_field>div {
  padding: 0 !important;
}

.loader_loading {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 50%;
  left: 0%;

  figure {
    width: 50px;
    height: 50px;

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
}

.cross_icn svg {
  position: absolute;
  right: 5px;
  top: 5px;
  color: white;
  cursor: pointer;
  background: red;
  border-radius: 100%;
  font-size: 20px;
  padding: 3px;
}

.cross_icn2 svg {
  position: absolute;
  right: 40%;
  bottom: -5px;
  cursor: pointer;
  background: red;
  color: white;
  border-radius: 100%;
  font-size: 20px;
  padding: 3px;
  z-index: 1;
}

.resend_txt {
  font-size: 16px;
  font-weight: 500;
  padding-top: 16px;
  text-align: center;
}

.resend_txt_2 {
  cursor: pointer;

  &:hover {
    color: $c_secondary;
  }
}

.text_field>div {
  padding-right: 0 !important;
}

.property_grid {
  >li {
    width: calc(100% / 3);

    @media (max-width: 991.98px) {
      width: 49.333333%;
    }

    @media (max-width: 600px) {
      width: 100%;
    }
  }

  +.text_center {
    margin-top: 60px;
  }
}

.single_property {
  position: relative;
  padding: 15px 15px 20px;
  border: 1px solid #707070;
  background-color: #252525;
  border-radius: 20px;
  height: 100%;
  cursor: pointer;
  transition: $transition;

  @media (max-width: 1199.98px) {
    padding: 10px 10px 15px;
    border-radius: 10px;
  }

  .tag {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 12px;
    font-weight: 400;
    padding: 5px 10px;
    border-radius: 30px;
    line-height: 1.2;
    z-index: 1;

    &.c_success {
      color: $c_white;
      background-color: $c_success;
    }

    &.c_danger {
      color: $c_white;
      background-color: $c_danger;
    }

    &.c_warning {
      color: #252525;
      background-color: $c_secondary;
    }

    &_active {
      position: absolute;
      right: 10px;
      top: 10px;
      width: 8px;
      height: 8px;
      border-radius: 30px;
      background-color: $c_active;
      z-index: 1;
    }
  }

  figure {
    width: 100%;
    aspect-ratio: 1/0.6657;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 15px;
    position: relative;

    svg {
      position: absolute;
      right: 10px;
      top: 10px;
      color: #F90052;
      z-index: 1;
      background-color: $c_white;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      padding: 6px;
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center;
      transition: $transition;
    }

    +div {
      margin-bottom: 10px;
      align-items: center;
      gap: 20px;

      @media (max-width: 1199.98px) {
        margin-bottom: 5px;
      }
    }
  }

  h3 {
    color: $c_white;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 10px;

    @media (max-width: 1199.98px) {
      font-size: 18px;
    }
  }

  h4 {
    color: $c_white;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 0;
    transition: $transition;

    @media (max-width: 1199.98px) {
      font-size: 16px;
    }

    +span[class*="starRatingWrap"] {
      display: inline-flex;

      >span {
        display: inline-flex;
        flex-direction: column;

        >span {
          display: inline-flex;

          svg {
            flex-shrink: 0;
          }
        }
      }
    }
  }

  p {
    font-size: 15px;
    margin-bottom: 20px !important;

    @media (max-width: 1199.98px) {
      font-size: 14px;
      margin-bottom: 15px !important;
    }

    span {
      color: $c_secondary;
    }
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    gap: 10px 15px;

    li {
      font-size: 15px;
      display: inline-flex;
      align-items: center;
      gap: 5px;

      @media (max-width: 1199.98px) {
        font-size: 13px;
      }
    }
  }

  &:hover {
    background-color: #131313;
    border-color: $c_secondary;
    transform: translateY(-5px);

    h4 {
      color: $c_secondary;
    }

    figure {
      img {
        transform: scale(1.1);
      }
    }

  }
}

.v2_grid {
  .single_property {
    border: none;
    border-radius: 10px;
    padding: 10px 10px 15px;
    box-shadow: 0px 10px 32px -4px rgba(1, 1, 1, 0.10), 0px 6px 14px -6px rgba(1, 1, 1, 0.12);

    figure {
      aspect-ratio: 1/0.75;
      border-radius: 10px;
      margin: -10px -10px 15px;
      width: auto;
      position: relative;

      p {
        position: absolute;
        background: red;
        padding: 3px 9px;
        font-size: 12px;
        border-radius: 6px;
        top: 10px;
        right: 10px;
        z-index: 1;
      }
    }

    h3 {
      padding: 8px 12px;
      font-size: 12px;
      font-weight: 600;
      border-radius: 3px;
      background-color: #373737;
      display: inline-block;
      transition: $transition;
    }

    h4 {
      font-size: 16px;
      font-weight: 600;
    }

    p {
      font-size: 14px;

      &:not(:last-child) {
        margin-bottom: 15px;
      }
    }

    ul {
      li {
        font-size: 12px;
        color: #9AA4B2;
      }
    }

    .flex {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      gap: 20px;
      margin-bottom: 15px;

      p {
        color: $c_white;
        font-size: 12px;
        font-weight: 400;
        margin-bottom: 0;

        small {
          font-size: 10px;
          display: block;
          margin-top: 5px;
        }
      }

      span {
        font-size: 10px;
        font-weight: 400;
        line-height: 1.2;
        color: $c_secondary;
        border-radius: 30px;
        background: rgba(205, 255, 0, 0.08);
        padding: 5px 10px;
        white-space: nowrap;
        max-width: 80px;
        min-width: 55px;
        display: inline-block;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    &:hover {
      background-color: transparent;
      box-shadow: 0px 10px 32px -4px rgba(1, 1, 1, 0.2), 0px 6px 14px -6px rgba(1, 1, 1, 0.2);

      h3 {
        color: #252525;
        background-color: $c_secondary;
      }
    }
  }
}

.news_list {
  .single {
    // align-items: center;
    cursor: pointer;

    @media (max-width: 1199.98px) {
      align-items: center;
    }

    @media (max-width: 991.98px) {
      justify-content: center;
    }

    @media (max-width: 600px) {
      --gap_x: 0px;
      --gap_y: 0px;
      gap: 20px 0;
      border-radius: 10px;
      padding: 15px;
      border: 1px solid #707070;
    }

    &:not(:last-child) {
      margin-bottom: 70px;

      @media (max-width: 600px) {
        margin-bottom: 15px
      }

    }

    &:nth-child(odd) {
      flex-direction: row-reverse;

      @media (max-width: 1199.98px) {
        align-items: center;
      }

      @media (max-width: 991.98px) {
        flex-direction: column;
      }
    }

    >* {
      width: calc(50% - var(--gap_x));

      @media (max-width: 991.98px) {
        width: calc(100% - var(--gap_x));
        text-align: center;
      }
    }

    .news_info {
      p {
        margin-bottom: 0;

        &.c_secondary {
          color: $c_secondary;

          @media (max-width: 1199.98px) {
            font-size: 14px;
          }
        }
      }

      h3 {
        font-size: 24px;
        margin: 10px 0 20px;
        font-weight: 600;
        line-height: 1.4;
        transition: $transition;

        @media (max-width: 1199.98px) {
          font-size: 22px;
          margin: 10px 0 15px;
        }

        @media (max-width: 991.98px) {
          font-size: 22px;
        }

        @media (max-width: 600px) {
          font-size: 18px;
        }
      }
    }

    >figure {
      height: 230px;
      border-radius: 10px;
      overflow: hidden;

      @media (max-width: 1399.98px) {
        max-width: 600px;
      }

      @media (max-width: 600px) {
        height: 200px;
        max-width: 100%;
        width: 100%;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        transition: $transition;
      }
    }

    &:hover {
      .news_info {
        h3 {
          color: $c_secondary;
        }
      }

      >figure {
        img {
          transform: scale(1.1);
        }
      }
    }
  }
}

.blogs_list {
  .single {
    display: flex;
    // align-items: center;
    gap: 50px;
    cursor: pointer;

    @media (max-width: 1199.98px) {
      flex-wrap: wrap;
      justify-content: center;
      gap: 20px;
      border-radius: 10px;
      padding: 30px;
      border: 1px solid #707070;
    }

    @media (max-width: 600px) {
      padding: 15px !important;
    }

    &:not(:last-child) {
      margin-bottom: 30px;
      padding-bottom: 30px;
      border-bottom: 1px solid #707070;

      @media (max-width: 600px) {
        margin-bottom: 15px;
      }
    }

    .blog_info {
      width: 100%;

      @media (max-width: 1199.98px) {
        text-align: center;
      }

      p {
        margin-bottom: 0;

        &.rounded {
          display: inline-flex;
          align-items: center;
          height: 36px;
          padding: 2px 24px;
          border: 1px solid #828282;
          border-radius: 50px;
          font-size: 14px;
          font-weight: 500;
          color: $c_secondary;
        }
      }

      h3 {
        font-size: 26px;
        margin: 20px 0;
        font-weight: 600;
        line-height: 1.4;
        transition: $transition;

        @media (max-width: 1399.98px) {
          font-size: 24px;
        }

        @media (max-width: 1199.98px) {
          font-size: 22px;
          margin: 15px 0;
        }

        @media (max-width: 600px) {
          font-size: 18px;
        }
      }
    }

    >figure {
      width: 325px;
      min-width: 325px;
      height: 200px;
      border-radius: 10px;
      overflow: hidden;

      @media (max-width: 1399.98px) {
        width: 300px;
        min-width: 300px;
      }

      @media (max-width: 600px) {
        width: 100%;
        min-width: 1px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        transition: $transition;
      }
    }

    &:hover {
      .blog_info {
        h3 {
          color: $c_secondary;
        }
      }

      >figure {
        img {
          transform: scale(1.1);
        }
      }
    }
  }
}

button.btn.btn_primary {
  &:disabled {
    cursor: no-drop;

  }
}

::-webkit-scrollbar {
  width: 3px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
  background-color: darkgrey;
}

.text_field_error {
  input {
    width: 100%;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    border-radius: 8px !important;
    background: #373737;
    outline: none;
    box-shadow: none;
    box-sizing: border-box;
    font-family: "Bw Modelica";
    transition: all 0.3s ease-in-out 0s;
    border: 1px solid transparent !important;
  }
}

.claim_pro {
  cursor: pointer;
  color: rgb(205, 255, 0) !important;
  text-align: right;
  width: 100%;
  display: flex !important;
  justify-content: flex-end !important;
}

.no_pro_img {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;

  figure {
    width: 160px;
    height: 120px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  p {
    padding-top: 17px;
  }
}

li.active_cls {
  color: red;
}

.status {
  font-weight: 600;

  &:hover {
    color: $c_secondary;
    text-decoration: underline;
  }
}

.no_property {
  figure {
    width: 240px;
    height: 150px;
    margin: auto;

    img {
      width: 100%;
      height: 100%;
    }
  }

  h3 {
    font-size: 17px;
    text-align: center;
    color: $c_secondary;
  }

  p {
    text-align: center;
    padding-top: 5px;

    span {
      color: $c_secondary;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.flex-pr {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;

  @media screen and (max-width: 600px) {
    flex-direction: column;
    gap: 5px;
    margin-bottom: 20px;
  }

  &+h3 {
    margin-top: 40px;
  }

  h4 {
    font-size: 14px;

  }

  h5 {
    font-size: 14px;
    color: $c_secondary;
  }
}

.edit-style {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 600px) {
    flex-direction: column;
    gap: 10px;
  }

  h4 {
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    min-height: 35px;
    min-width: 1px;
    padding: 2px 20px;
  }
}

.copyAddress {
  cursor: pointer;

  svg {
    font-size: 16px;
    margin-left: 5px;
  }

  &:hover {
    color: $c_secondary;
  }
}

.disclaimer {
  h4 {
    font-size: 19px;
    text-align: center;
    color: #CDFF00;
    margin-top: 20px;
  }

  .labeldiv {
    margin: 10px 0px 30px 0;

    &>label {
      display: flex !important;
      align-items: flex-start !important;
      margin-bottom: 10px !important;

    }

    span {
      padding-top: 0 !important;

      svg {
        color: #CDFF00;
      }

      &:hover {
        background: transparent !important;
      }
    }
  }

  .btndiv {
    display: flex;
    justify-content: center;
  }
}

.close_btn1 {
  position: absolute;
  top: -40px;
  right: -30px;
  cursor: pointer;
}

.augustdiv {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;

  svg {
    cursor: pointer;
    color: $c_secondary;
    ;
  }

  h3 {
    margin-bottom: 0 !important;
    padding: 0px 30px;
    width: 400px;
    text-align: center;
  }
}

button {
  &:disabled {
    cursor: not-allowed;
  }
}

.kyc {
  display: flex;
  align-items: center;

  p {
    display: flex;
    align-items: center;
    color: white;

    span {
      display: flex;
      align-items: center;
      padding-left: 5px;
      padding-right: 10px;

      svg {
        padding-left: 3px;
        // color: $c_secondary;
      }

      p {
        cursor: pointer;
        font-size: 16px;
        color: $c_secondary;
        font-weight: 700;
        margin-left: 10px;
      }

      &:hover {
        color: $c_white !important;
        cursor: default;
      }
    }

    span.red-text {
      color: red !important;

      &:hover {
        color: red !important;
      }
    }
  }
}

.list_data {
  li {
    span {
      a {
        color: $c_white;

        &:hover {
          color: $c_secondary;

        }
      }
    }
  }
}

.download_icn {
  cursor: pointer;
  font-size: 19px;
  margin-top: 5px;
  margin-right: 4px;
}

a.download_a {
  font-size: 14px;
  cursor: pointer;
  color: #54abce;

  &:hover {
    text-decoration: underline;
  }
}

.rate_now {
  margin: 0;
  font-size: 16px !important;
  color: $c_secondary !important;
  cursor: pointer;
  margin-bottom: 0px !important;
}

@media (max-width: 1199.98px) {
  .ut_spc .right_side .form_control .upload_image {
    margin: 0px;
  }
}

@media (max-width: 600px) {
  .upload_document.w_50 {
    width: 100%;
  }

  .flex-pr h5 {
    word-break: break-word;
    width: 100%;
  }

  .flex-pr h4 {
    width: 100%;
  }

  .profile_mnv .gap_p {
    flex-direction: column;
  }

  .profile_mnv .form_control.w_50 {
    width: 100%;
    margin-top: 0px;
  }

  .form_control.uplod_img {
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
  }
}