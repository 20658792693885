@import "../../variable.scss";

.myPropertiesTab_sc {
    .main_tab {
        margin-bottom: 40px;

        @media (max-width: 1399.98px) {
            margin-bottom: 25px;
        }

        @media (max-width: 1199.98px) {
            margin-bottom: 15px;
        }

        .MuiTabs {
            &-scroller {
                display: inline-flex;
            }

            &-flexContainer {
                width: 100%;
                justify-content: center;
                gap: 20px;

                @media (max-width: 767.98px) {
                    gap: 10px;
                }
            }

            &-indicator {
                border-radius: 10px;
                height: 100%;
                z-index: -1;
            }
        }

        .MuiButtonBase-root {
            color: $c_white;
            font-size: 20px;
            font-weight: 500;
            padding: 10px 20px;
            border-radius: 10px;
            border: none;
            line-height: normal;
            letter-spacing: normal;
            text-transform: capitalize;
            max-width: 100%;
            min-width: 1px;
            position: relative;
            min-height: 1px;
            transition: $transition;
            font-family: $f_body;

            @media (max-width: 1399.98px) {
                font-size: 18px;
            }

            @media (max-width: 1199.98px) {
                font-size: 16px;
                padding: 10px 15px;
                border-radius: 5px;
            }

            @media (max-width: 767.98px) {
                font-size: 14px;
                padding: 10px 15px;
                white-space: normal;
                width: calc(50% - 5px);
                line-height: 1.2;
            }

            &.Mui-selected {
                color: #252525;
                font-weight: 700;
            }
        }
    }

    .sub_tab {
        margin-bottom: 40px;

        @media (max-width: 1399.98px) {
            margin-bottom: 25px;
        }

        @media (max-width: 1199.98px) {
            margin-bottom: 20px;
        }

        .MuiTabs {
            &-scroller {
                display: inline-flex;
            }

            &-flexContainer {
                width: 100%;
                gap: 40px;
            }
        }

        .MuiButtonBase-root {
            color: $c_white;
            font-size: 16px;
            font-weight: 500;
            padding: 10px 0;
            border-radius: 0;
            border: none;
            line-height: normal;
            letter-spacing: normal;
            text-transform: capitalize;
            max-width: 100%;
            min-width: 1px;
            position: relative;
            min-height: 1px;
            transition: $transition;
            font-family: $f_body;

            @media (max-width: 767.98px) {
                font-size: 14px;
            }
        }
    }

    .property_grid {
        >li {
            width: calc(100% / 4);

            @media (max-width: 1199.98px) {
                width: 33%;
            }

            @media (max-width: 991.98px) {
                width: 50%;
            }

            @media (max-width: 600px) {
                width: 100%;
            }

        }
    }
}