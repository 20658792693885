@import "../../variable.scss";

.propertyListing_sc {
    padding-top: 20px;

    @media (max-width: 1199.98px) {
        flex-direction: column;
    }

    .head {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: sticky;
        top: 121px;
        background-color: #252525;
        padding: 20px 0 30px;
        z-index: 2;
        display: none;

        h1 {
            color: $c_white;
            font-size: 36px;
            font-weight: 600;

            @media (max-width: 1399.98px) {
                font-size: 30px;
            }

            @media (max-width: 1199.98px) {
                font-size: 28px;
            }

            @media (max-width: 991.98px) {
                font-size: 25px;
            }

            @media (max-width: 600px) {
                font-size: 22px;
            }
        }

        .btn {
            font-size: 16px;
            font-weight: 400;
            text-transform: capitalize;
            min-height: 45px;
            min-width: 1px;
            padding: 2px 20px;
        }
    }

    :is(.left_side, .right_side) {
        width: 50%;

        @media (max-width: 1199.98px) {
            width: 100%;
        }
    }

    .left_side {
        .inner_head {
            display: flex;
            align-items: center;
            justify-content: space-between;
            order: -1;
            min-height: 95px;
            // position: sticky;
            // top: 121px;
            background-color: #252525;
            z-index: 2;

            .filter_btn {
                display: none;

                @media (max-width: 1199.98px) {
                    display: block;
                }

                button.btn.btn_primary.br {
                    min-width: 150px;
                    padding: 0px;
                    min-height: 50px;

                    @media (max-width: 767.98px) {
                        min-width: 130px;
                        min-height: 41px
                    }

                    @media (max-width: 600px) {
                        min-width: 105px;
                        min-height: 39px;
                        font-size: 15px;
                    }
                }
            }

            h1 {
                color: $c_white;
                font-size: 36px;
                font-weight: 600;

                @media (max-width: 1399.98px) {
                    font-size: 30px;
                }

                @media (max-width: 1199.98px) {
                    font-size: 28px;
                }

                @media (max-width: 991.98px) {
                    font-size: 25px;
                }

                @media (max-width: 600px) {
                    font-size: 22px;
                }
            }
        }

        .property_grid {
            >li {
                width: calc(100% / 2);

                @media (max-width: 1199.98px) {
                    width: 33%;

                }

                @media (max-width: 991.98px) {
                    width: 50%;

                }

                @media (max-width: 600px) {
                    width: 100%;

                }
            }
        }
    }

    .right_side {
        @media (max-width: 1199.98px) {
            padding-top: 30px;
        }

        .filter_btn {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            min-height: 95px;
            position: sticky;
            top: 95px;
            background-color: #252525;
            z-index: 2;

            @media (max-width: 1199.98px) {
                display: none;
            }

            .btn {
                font-size: 16px;
                font-weight: 400;
                text-transform: capitalize;
                min-height: 45px;
                min-width: 1px;
                padding: 2px 20px;
            }
        }

        .map {
            position: sticky;
            top: calc(121px + 95px);
            display: flex;
            height: calc(100vh - (121px + 95px + 15px));

            @media (max-width: 1199.98px) {
                top: 0;
                position: initial;
                height: 400px;
            }

            iframe {
                height: 100%;
                width: 100%;
                border: none;
                filter: invert(.9);
            }
        }
    }
}

.location_swiper {
    display: flex;
    flex-direction: column;

    .swiper-wrapper {
        margin-bottom: 40px;
    }

    .single_slide {
        cursor: pointer;

        figure {
            width: 100%;
            aspect-ratio: 1/1;
            border-radius: 20px;
            overflow: hidden;
            margin-bottom: 15px;

            @media screen and (max-width: 1199.98px) {
                border-radius: 10px;
            }

            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
                object-position: center;
                transition: $transition;
            }
        }

        h3 {
            color: $c_white;
            font-size: 22px;
            font-weight: 700;
            margin-bottom: 5px;
            transition: $transition;
            text-transform: capitalize;

            @media (max-width: 1199.98px) {
                font-size: 16px;
            }
        }

        p {
            color: #9AA4B2;
            font-weight: 400;
        }

        &:hover {
            h3 {
                color: $c_secondary;
            }

            figure {
                img {
                    transform: scale(1.1);
                }
            }
        }
    }

    .swiper_action {
        display: flex;
        align-items: center;
        gap: 15px;

        @media (max-width: 600px) {
            gap: 15px;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 0);
        }

        :is(.swiper-button-next, .swiper-button-prev) {
            position: initial;
            margin: 0;
            transform: none;
            height: auto;
            width: auto;

            svg {
                fill: rgba(154, 164, 178, 1);
                width: 22px;
                height: 22px;
                transition: $transition;
            }

            &::after {
                display: none;
            }

            &:hover {
                svg {
                    fill: $c_secondary;
                }
            }
        }

        .swiper-pagination {
            position: initial;
            transform: none !important;

            .swiper-pagination-bullet {
                margin: 0 3px;
                background-color: #373737;
                opacity: 1;

                &.swiper-pagination-bullet-active {
                    background-color: $c_secondary;
                }
            }
        }
    }
}

.propertyFilter {
    position: fixed;
    inset: 0;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.5);
    transform: translate(100%, 0%);
    opacity: 0;
    transition: $transition;

    &.active {
        transform: translate(0%, 0%);
        opacity: 1;
    }

    .inner {
        display: flex;
        justify-content: flex-end;

        .overlay {
            width: calc(100% - 670px);

            @media (max-width: 1199.98px) {
                width: calc(100% - 550px);
            }

            @media (max-width: 991.98px) {
                width: calc(100% - 500px);
            }

            @media (max-width: 767.98px) {
                width: 0%;
            }
        }

        .filter_form {
            height: 100vh;
            overflow: auto;
            max-width: 670px;
            width: 100%;
            background-color: #252525;
            padding: 25px;

            @media (max-width: 1199.98px) {
                max-width: 550px
            }

            @media (max-width: 991.98px) {
                max-width: 500px
            }

            @media (max-width: 767.98px) {
                max-width: 100%;
                width: 100%;
                padding: 20px;
            }
        }

        .filter_head {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 25px;

            h2 {
                color: $c_white;
                font-size: 18px;
                font-weight: 400;
            }

            .close_btn {
                display: inline-flex;
                transition: $transition;
                cursor: pointer;

                svg {
                    font-size: 25px;
                }

                &:hover {
                    color: #ff0000;
                }
            }

            .reset_btn {
                height: 50px;
                border-radius: 20px;
                background-color: #373737;
                padding: 5px 20px;
                color: #9AA4B2;
                font-size: 18px;
                font-weight: 500;
                border: none;
                transition: $transition;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                @media (max-width: 767.98px) {
                    height: 40px;
                    border-radius: 10px;
                    padding: 5px 10px;
                    font-size: 14px;
                }

                &:hover {
                    color: #252525;
                    background-color: $c_secondary;
                }
            }
        }

        .filter_body {
            label {
                color: $c_white;
                font-size: 18px;
                font-weight: 400;
                margin-bottom: 15px;

                @media (max-width: 1199.98px) {
                    font-size: 16px;
                }
            }

            .form_control {
                @media (max-width: 767.98px) {
                    margin-bottom: 30px;
                }
            }

            .MuiSlider-root {
                @media (max-width: 767.98px) {
                    padding: 8px 0;
                }
            }

            .MuiFormGroup-root {
                display: flex;
                flex-direction: row;
                gap: 10px;

                label {
                    font-size: 14px;
                    font-weight: 400;
                    color: #9AA4B2;
                    display: inline-flex;
                    padding: 10px 20px;
                    border-radius: 10px;
                    border: 1px solid #EEF2F6;
                    width: auto;
                    margin: 0;
                    transition: $transition;

                    @media (max-width: 767.98px) {
                        padding: 10px;
                    }

                    span {
                        font-size: 14px;
                        font-family: $f_body;

                        @media (max-width: 767.98px) {
                            line-height: 1.3;
                        }
                    }

                    &:hover {
                        color: $c_white;
                        border-color: $c_white;
                    }

                    .MuiCheckbox-root {
                        display: none;
                    }

                    &:has(input:checked) {
                        color: $c_secondary;
                        border-color: $c_secondary;
                    }
                }
            }

            .MuiSlider-rail {
                background-color: $c_white;
                opacity: 1;
            }

            .form_btn {
                text-align: center;

                .btn {
                    min-width: 465px;

                    @media (max-width: 767.98px) {
                        min-width: 100%;
                    }

                }
            }
        }
    }
}



@media (max-width: 1199.98px) {
    .property_grid.gap_p {
        flex-direction: row;
    }

}

@media (max-width: 600px) {
    .prprty_mrkt .single_property {
        margin-top: 40px;
    }

}