/// header css start here
@import '../assets/theme/Variable.scss';

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  background-color: transparent;
  border-bottom: 1px dashed #707070;

  @media (max-width: 991.98px) {
    &:has(.wlr_lgb_tn .btn_primary.br) {
      padding-top: 50px;

      nav {
        .menu {
          top: calc(96px + 50px);
        }
      }

      ~main {
        padding-top: calc(96px + 50px);
      }
    }
  }

  @media (max-width: 767.98px) {
    &:has(.wlr_lgb_tn .btn_primary.br) {
      nav {
        .menu {
          top: calc(81px + 50px);
        }
      }

      ~main {
        padding-top: calc(81px + 50px);
      }
    }
  }

  &.scrolled {
    background-color: $c_primary;
  }

  nav {
    width: 100%;
    min-height: 120px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0;

    @media (max-width: 1399.98px) {
      min-height: 1px;
      padding: 10px 0;
    }

    .menu {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 15px;

      @media screen and (max-width: 991.98px) {
        flex-direction: column;
        background-color: #131313;
        align-items: start;
        position: absolute;
        top: 96px;
        left: 0;
        width: 100%;
        z-index: 1;
        transform: scale(1, 0);
        transform-origin: top;
        transition: transform 0.3s ease-in-out;
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
        padding: 15px 15px;
        gap: 0;
      }

      @media screen and (max-width: 767.98px) {
        top: 81px;
      }

      li {
        @media screen and (max-width: 991.98px) {
          margin-bottom: 0;
          width: 100%;
        }

        a {
          display: block;
          padding: 10px 15px;
          font-size: 16px;
          font-weight: 500;
          color: $c_body;
          line-height: 1.2;

          @media screen and (max-width: 1199.98px) {
            padding: 10px 10px;
            font-size: 14px;
          }

          @media screen and (max-width: 991.98px) {
            width: 100%;
            padding: 12px 0;
            text-align: center;
          }

          &:hover {
            color: $c_secondary;
          }
        }

        &.active_cls {
          a {
            color: $c_secondary;

            &:hover {
              color: $c_secondary;
            }
          }
        }
      }
    }
  }
}

.logo {
  a {
    font-size: 50px;
    font-weight: 400;
    color: $c_body;
    font-family: $f_wains;
    line-height: 1;
  }
}

.menu-icon {
  display: none;

  @media only screen and (max-width: 991.98px) {
    display: inline-flex;
    align-items: center;
    cursor: pointer;

    svg {
      color: $c_white;
      font-size: 28px;
    }
  }
}

#menu-toggle {
  display: none;

  &:checked {
    ~ {
      .menu {
        transform: scale(1, 1);
      }
    }
  }
}

.wlr_lgb_tn {
  display: flex;
  align-items: center;
  gap: 25px;

  @media screen and (max-width: 1199.98px) {
    gap: 10px;
  }

  @media screen and (max-width: 991.98px) {
    margin-left: auto;
    margin-right: 30px;
  }

  @media screen and (max-width: 600px) {
    margin-right: 20px;
  }

  .btn {
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    min-height: 40px;
    min-width: 1px;
    padding: 2px 15px;

    figure {
      display: inline-flex;
      position: relative;
      top: -1px;
    }

    &_primary {
      &.br {
        gap: 14px;

        @media screen and (max-width: 991.98px) {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          border-radius: 0;
          height: 50px;
          background: #000;
          border: none;
        }
      }
    }

    &_secondary {
      gap: 5px;
    }
  }
}

// header css end


// footer css
.site_footer {
  background-color: #252525;

  .conta_iner {
    position: relative;

    &::before {
      content: "";
      width: 100%;
      height: 1px;
      background: #ababab;
      top: 90px;
      position: absolute;
      left: 0;
      opacity: 0.44;

      @media (max-width: 1399.98px) {
        top: 100px;
      }

      @media (max-width: 991.98px) {
        display: none;
      }
    }
  }

  .gap_p {
    flex-wrap: nowrap;
    justify-content: space-between;

    @media (max-width: 991.98px) {
      --gap_x: 100px;
      flex-wrap: wrap;
      justify-content: flex-start;
    }
  }

  .single {
    width: auto;
    max-width: 650px;

    @media (max-width: 1199.98px) {
      max-width: 450px;
    }

    @media (max-width: 991.98px) {
      max-width: 100%;
    }

    .logo {
      margin-bottom: 50px;

      @media (max-width: 991.98px) {
        margin-bottom: 20px;
      }
    }

    p {
      font-size: 14px;

      @media (max-width: 1199.98px) {
        br {
          display: none;
        }
      }
    }

    h2 {
      font-size: 16px;
      text-transform: uppercase;
      margin-bottom: 51px;
      font-weight: 500;
      margin-top: 30px;

      @media (max-width: 1199.98px) {
        font-size: 14px;
        margin-bottom: 50px;
      }

      @media (max-width: 991.98px) {
        margin-bottom: 30px;
      }

      @media (max-width: 600px) {
        margin-bottom: 25px;
      }
    }

    ul {
      li {
        display: flex;
        line-height: 1.2;

        @media (max-width: 1199.98px) {
          font-size: 14px;
        }

        &:not(:last-child) {
          margin-bottom: 20px;

          @media (max-width: 600px) {
            margin-bottom: 15px;
          }
        }

        a {
          color: $c_body;
          font-weight: 500;

          &:hover {
            color: $c_secondary;
          }
        }

        &.scl_icon {
          display: flex;
          align-items: center;
          gap: 15px;

          .f_flex {
            display: inline-flex;
            align-items: center;
            gap: 10px;

            a {
              display: inline-flex;
            }

            img {
              height: 30px;

              @media (max-width: 600px) {
                height: 25px;
              }
            }

            svg {
              font-size: 30px;

              @media (max-width: 600px) {
                font-size: 25px;
              }
            }
          }
        }
      }
    }
  }
}

.copy_right {
  text-align: center;
  padding: 20px 0;
  background: $c_secondary;

  p {
    color: $c_black;
    font-weight: 600;
    font-size: 14px;

    a {
      color: $c_black;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.account_dropdown {
  .MuiPaper-root {
    background-color: $c_darkgray;
    border-radius: 20px 0px 20px 20px;
    box-shadow: 0px 10px 32px -4px rgba(1, 1, 1, 0.10), 0px 6px 14px -6px rgba(1, 1, 1, 0.12);
    margin-top: 10px;
    width: 250px;

    @media screen and (max-width: 767.98px) {
      border-radius: 10px 0px 10px 10px;
      width: 220px;
    }

    ul {
      padding: 15px 0;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 0;

      @media screen and (max-width: 767.98px) {
        padding: 10px 0;
      }

      li {
        padding: 10px 20px;
        width: 100%;
        display: flex;
        align-items: center;
        gap: 20px;
        color: $c_white;
        font-size: 16px;
        font-weight: 400;
        font-family: $f_body;
        transition: $transition;

        @media screen and (max-width: 767.98px) {
          padding: 8px 15px;
          font-size: 14px;
          gap: 12px;
          min-height: 1px;
        }

        figure {
          width: 20px;
          min-width: 20px;
          height: 20px;
          display: inline-flex;

          @media screen and (max-width: 767.98px) {
            width: 18px;
            min-width: 18px;
            height: 18px;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
          }
        }

        svg {
          width: 20px;
          min-width: 20px;
        }

        &:hover {
          background-color: rgba(255, 255, 255, .1);
        }
      }

      @media screen and (max-width: 767.98px) {
        .walletLine>div {
          min-height: 1px;
        }
      }
    }
  }
}

.drpdwn_icns {
  padding: 0 !important;
  color: white !important;
}

.logo {
  display: inline-flex;

  figure {
    height: 75px;
    cursor: pointer;
    display: inline-flex;

    @media (max-width: 991.98px) {
      margin-top: 0 !important;
    }

    @media (max-width: 600px) {
      height: 60px;
    }

    img {
      height: 100%;
    }
  }
}

.matic {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  h4 {
    font-size: 14px;
    padding-right: 10px;

    @media screen and (max-width: 767.98px) {
      font-size: 12px;
    }

    &:last-child {
      margin-bottom: 0px;
    }
  }

  h5 {
    font-size: 14px;
    color: $c_secondary;

    @media screen and (max-width: 767.98px) {
      font-size: 12px;
    }
  }
}

.walletLine {
  margin: 0px !important;
  padding: 0px !important;
  box-shadow: none !important;

  >div {
    padding: 0 !important;
    margin: 0 !important;
    min-height: 1px !important;

    .MuiAccordionDetails-root {
      padding: 5px 20px 10px !important;

      @media screen and (max-width: 767.98px) {
        padding: 5px 15px 10px !important;
      }
    }
  }
}

.walletLine22222222 {
  >div {
    padding: 0 !important;
    margin: 0 !important;
  }
}