@import "../../variable.scss";

.property {
    &_gallery {
        figure {
            position: relative;

            >img {
                height: 100%;
                width: 100%;
                object-fit: cover;
                object-position: center;
                border-radius: 20px;
            }
        }

        .main_gallery {
            width: 66.5%;

            @media (max-width: 991.98px) {
                width: 100%;
            }

            figure {
                height: 450px;

                @media (max-width: 991.98px) {
                    height: 400px;
                }

                @media (max-width: 600px) {
                    height: 300px;
                }


                .tag {
                    position: absolute;
                    right: 15px;
                    top: 15px;
                    display: inline-flex;
                    align-items: center;
                    gap: 10px;
                    color: #252525;
                    font-size: 12px;
                    font-weight: 600;
                    padding: 10px 20px;
                    border-radius: 30px;
                    background-color: $c_secondary;
                }
            }
        }

        .other_gallery {
            width: 33.5%;
            display: flex;
            flex-direction: column;
            gap: 15px;

            @media (max-width: 991.98px) {
                width: 100%;
                flex-direction: row;
                flex-wrap: wrap;
            }

            figure {
                flex-grow: 1;

                @media (max-width: 991.98px) {
                    width: 49%;
                    height: 300px;
                }

                @media (max-width: 767.98px) {
                    height: 200px;
                }

                >img {
                    position: absolute;
                    inset: 0;

                    @media (max-width: 991.98px) {
                        position: inherit;
                    }
                }

                .more {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    display: inline-flex;
                    align-items: center;
                    min-height: 36px;
                    gap: 15px;
                    color: $c_white;
                    font-size: 14px;
                    font-weight: 400;
                    padding: 2px 20px;
                    border-radius: 30px;
                    border: 2px solid $c_white;
                    background: rgba(37, 37, 37, 0.80);
                    white-space: nowrap;
                    transition: $transition;
                    cursor: pointer;

                    img {
                        height: 20px;
                    }

                    &:hover {
                        background-color: rgba(37, 37, 37, 1);
                        border-color: $c_secondary;
                    }
                }
            }
        }
    }

    &_description {
        margin-top: 40px;

        @media (max-width: 1199.98px) {
            .gap_p {
                flex-direction: column;
            }

        }

        .left_side {
            width: 66.5%;

            @media (max-width: 1199.98px) {
                width: 100%;
            }

            .report_p {
                h3 {
                    margin: 0;
                    font-size: 17px;
                    color: $c_secondary;
                    cursor: pointer;
                    padding-top: 10px;
                }
            }

            .fav_div {
                display: flex;
                justify-content: space-between;
                align-items: center;

                h2 {
                    color: $c_white;
                    font-size: 36px;
                    font-weight: 600;
                    margin-bottom: 10px;

                    @media (max-width: 1199.98px) {
                        font-size: 30px;


                    }

                    @media (max-width: 991.98px) {
                        font-size: 26px;


                    }

                    @media (max-width: 1199.98px) {
                        font-size: 22px;


                    }

                }

                svg {
                    cursor: pointer;
                }
            }


            h6 {
                font-size: 17px;
                cursor: pointer;
                color: $c_secondary;
                margin-top: 11px;
            }

            h3 {
                color: $c_white;
                margin-top: 30px;
                font-size: 24px;
                font-weight: 600;
                margin-bottom: 15px;

                @media (max-width: 600px) {
                    font-size: 20px;

                }
            }

            p {
                color: #9AA4B2;
                font-size: 14px;
                word-wrap: break-word;
            }

            ul {
                display: flex;
                flex-wrap: wrap;
                gap: 20px;

                li {
                    color: #9AA4B2;
                    font-size: 16px;
                    display: inline-flex;
                    align-items: center;
                    gap: 8px;

                    img {
                        position: relative;
                        top: -1px;
                    }
                }
            }
        }

        .right_side {
            width: 33.5%;

            @media (max-width: 1199.98px) {
                width: 100%;
            }

            .box {
                padding: 20px;
                border-radius: 20px;
                background-color: #373737;
                position: relative;

                @media (max-width: 767.98px) {
                    text-align: center;
                    padding: 20px 15px;
                    border-radius: 10px;
                }

                .box_edit {
                    padding: 0;
                    border: none;
                    background-color: transparent;
                    cursor: pointer;
                    position: absolute;
                    right: 20px;
                    top: 20px;
                    z-index: 1;

                    &:hover {
                        img {
                            filter: brightness(0) invert(81%) sepia(80%) saturate(1030%) hue-rotate(15deg) brightness(103%) contrast(103%);
                        }
                    }
                }

                h4 {
                    color: $c_white;
                    font-size: 18px;
                    font-weight: 600;
                    margin-bottom: 10px;
                    padding-right: 40px;

                    @media (max-width: 767.98px) {
                        padding-right: 0px;
                        font-size: 16px;
                    }
                }

                p {
                    color: #9AA4B2;
                    font-size: 14px;
                    // cursor: pointer;

                    &.status {
                        text-align: center;
                        color: $c_secondary;
                        margin-bottom: 20px;
                    }
                }

                ul {
                    margin-block: 20px;

                    li {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        gap: 20px;
                        font-size: 14px;
                        color: $c_white;

                        &:not(:last-child) {
                            margin-bottom: 15px;
                        }
                    }
                }

                .btn {
                    width: 100%;

                    @media (max-width: 1199.98px) {
                        width: auto;
                    }
                }
            }
        }
    }

    &_boxes {
        margin-top: 40px;

        .box_group,
        .box {
            width: calc(100% / 2 - var(--gap_x));


            @media (max-width: 767.98px) {
                width: calc(100% / 1 - var(--gap_x));
            }

            &.w_100 {
                width: calc(100% - var(--gap_x));
            }

            h3 {
                color: $c_white;
                font-size: 24px;
                font-weight: 600;
                margin-bottom: 30px;

                @media (max-width: 1199.98px) {
                    font-size: 20px;
                }

                @media (max-width: 767.98px) {
                    font-size: 16px;
                    margin-bottom: 20px;
                }

                &:has(+h3) {
                    margin-bottom: 10px;
                }
            }
        }

        .box_group {
            h3 {
                margin-bottom: 20px;
            }

            .box {
                width: 100%;

                &:not(:last-child) {
                    margin-bottom: 30px;
                }

                @media (max-width: 1199.98px) {
                    .list_data li span {
                        font-size: 16px;
                    }
                }
            }
        }

        .box {
            background-color: #373737;
            border-radius: 20px;
            padding: 20px;

            @media (max-width: 991.98px) {
                padding: 10px 20px;
            }

            @media (max-width: 767.98px) {
                padding: 20px 15px;
                border-radius: 10px;
            }

            ul {
                &.list_data {
                    li {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        color: $c_white;
                        font-size: 18px;

                        @media (max-width: 767.98px) {
                            font-size: 13px;
                        }

                        .claim_pro {
                            display: flex;
                            align-items: center;
                            justify-content: flex-end;
                            color: $c_secondary !important;
                            font-size: 18px;
                            cursor: pointer;
                        }

                        &:not(:last-child) {
                            margin-bottom: 15px;

                            @media (max-width: 767.98px) {
                                margin-bottom: 10px;
                            }
                        }

                        span {
                            &.c_secondary {
                                color: $c_secondary;
                            }

                            &.c_secondary,
                            b {
                                font-weight: 700;
                            }
                        }

                        hr {
                            width: 100%;
                            border: none;
                            height: 1px;
                            display: block;
                            background-color: #EEF2F6;
                        }
                    }
                }

                &.list_document {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 15px 40px;

                    li {
                        display: inline-flex;
                        align-items: center;
                        color: $c_white;
                        gap: 10px;

                        img {
                            position: relative;
                            top: -1px;
                        }
                    }
                }

                &.gap_p {
                    --gap_x: 20px;
                    --gap_y: 25px;
                    margin-bottom: 25px;

                    li {
                        width: calc(100% / 3);
                        display: flex;
                        flex-direction: column;
                        gap: 5px;

                        strong {
                            color: $c_white;
                            font-weight: 400;
                        }

                        span {
                            font-size: 14px;
                            color: #9AA4B2;
                        }
                    }
                }
            }

            p {
                &.price_label {
                    font-size: 18px;
                    color: $c_white;
                    display: flex;
                    flex-direction: column;
                    gap: 5px;
                    margin-bottom: 25px;

                    strong {
                        font-size: 20px;
                    }
                }
            }

            &.address_box {
                padding: 0;
                background-color: transparent;

                h3 {
                    margin-bottom: 15px;
                }

                p {
                    font-size: 14px;
                    color: #9AA4B2;
                }
            }

            .map {
                display: flex;
                margin-top: 30px;
                border-radius: 20px;
                overflow: hidden;

                iframe {
                    width: 100%;
                    border: none;
                    height: 300px;
                    filter: invert(.9);

                    @media (max-width: 1199.98px) {
                        height: 400px;
                    }
                }
            }

            table {
                &:not(.simple_table) {
                    width: 100%;
                    border-collapse: collapse;
                    table-layout: fixed;
                    margin-top: 20px;

                    @media screen and (max-width: 767.98px) {
                        white-space: nowrap;
                        table-layout: auto;
                    }

                    :is(th, td) {
                        color: $c_white;
                        font-size: 16px;
                        border: 1px solid #EEF2F6;
                        text-align: left;
                        padding: 15px 20px;

                        @media screen and (max-width: 767.98px) {
                            font-size: 14px;
                            padding: 10px 15px;
                        }
                    }

                    th {
                        font-weight: 400;
                    }

                    td {
                        font-weight: 600;
                    }
                }

                &.simple_table {
                    width: 100%;
                    border-collapse: collapse;

                    :is(th, td) {
                        color: $c_white;
                        border-bottom: 1px solid #252525;
                        text-align: left;
                        padding: 15px 20px;

                        &:first-child {
                            padding-left: 0;
                        }

                        &:last-child {
                            padding-right: 0;
                        }
                    }

                    th {
                        font-size: 24px;
                        font-weight: 600;
                    }

                    td {
                        font-size: 14px;
                    }

                    thead {
                        th {
                            padding-top: 0;
                        }
                    }

                    tbody {
                        tr {
                            &:last-child {
                                td {
                                    padding-bottom: 0;
                                    border-bottom: none;
                                }
                            }
                        }
                    }
                }
            }

            .table_responisve {
                overflow: auto;
            }

            .progress {
                height: 16px;
                background-color: #252525;
                border-radius: 20px;
                width: 100%;
                display: flex;
                overflow: hidden;
                margin-top: -10px;

                &_status {
                    background-color: $c_secondary;
                    border-radius: 20px;
                    height: 100%;
                    transition: $transition;
                }

                &_selector {
                    display: flex;
                    gap: 30px;
                    margin-top: 30px;

                    .single {
                        display: inline-flex;
                        gap: 10px;

                        >span {
                            width: 36px;
                            height: 36px;
                            border-radius: 50%;
                            display: inline-flex;
                            background-color: $c_secondary;
                        }

                        p {
                            display: inline-flex;
                            flex-direction: column;
                            gap: 10px;
                            color: $c_white;
                            line-height: 1;

                            strong {
                                font-size: 20px;
                                font-weight: 300;
                            }
                        }

                        &:nth-child(2) {
                            >span {
                                background-color: #252525;
                            }
                        }
                    }
                }
            }
        }

        .Uploadbox_document {
            display: flex;
            flex-wrap: wrap;
            gap: 20px;

            .upload_document {
                .inner {
                    width: 275px;
                    height: 170px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: $c_darkgray;
                    border: 1px solid #EEF2F6;
                    border-radius: 10px;
                    position: relative;

                    @media (max-width: 767.98px) {
                        width: 260px;
                    }


                    @media (max-width: 600px) {
                        width: 100%;
                    }

                    svg {
                        font-size: 40px;
                    }

                    &:has(input) {
                        cursor: pointer;
                    }
                }

                figure {
                    height: 100%;
                    width: 100%;
                    padding: 10px;
                    position: relative;
                    border-radius: 10px;
                    overflow: hidden;

                    // &::before {
                    //     content: "";
                    //     position: absolute;
                    //     inset: 0;
                    //     background: linear-gradient(0deg, rgba(37, 37, 37, 0.80) 0%, rgba(37, 37, 37, 0.80) 100%);
                    // }

                    img {
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                        object-position: center;
                        border-radius: 8px;
                    }

                    svg {
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        font-size: 30px;
                        fill: $c_white;
                        cursor: pointer;
                    }
                }

                .close {
                    position: absolute;
                    right: 5px;
                    top: 5px;
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    background-color: #ff0000;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    color: $c_white;
                    cursor: pointer;

                    svg {
                        font-size: 18px;
                    }
                }

                input[type="file"] {
                    position: absolute;
                    inset: 0;
                    padding: 0;
                    height: 100%;
                    width: 100%;
                    opacity: 0;
                    cursor: pointer;
                    text-indent: -1000px;
                }
            }
        }
    }
}

.token_sold {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.select_tonn {
    padding: 20px 0;

    .select {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;

        h3 {
            background: $c_primary;
            font-size: 17px;
            padding: 7px 25px;
            border-radius: 5px;
            cursor: pointer;

            @media (max-width: 991.98px) {
                padding: 7px 15px;
            }

            &:hover {
                background: $c_secondary;
                color: $c_primary;
            }
        }

    }
}



.arrow_flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    h3 {
        margin-bottom: 0 !important;
    }

    p {
        svg {
            font-size: 35px;
            cursor: pointer;
        }
    }
}

@media (max-width: 991.98px) {
    .property_gallery .gap_p {
        flex-direction: column;
    }
}

@media (max-width: 600px) {

    .doc_page .upload_document {
        width: 100%;
    }

    .otp_sc>div {
        justify-content: center;
    }

    .otp_sc div input {
        margin-right: 8px !important;
    }

    .otp_sc div input:last-child {
        margin-right: 0px !important;
    }


    .doc_page .auth_page .right_side .upload_document .inner {
        padding: 20px;
        text-align: center;
    }

    .account_pge .form_control {
        width: 100%;
    }

    .otp_scren .right_side {
        text-align: center;
    }

    .form_control.upd_img {
        padding-bottom: 40px;
    }

    .account_pge .form_control {
        margin-top: 0px;
    }

    .token_sold p {
        width: 53%;
        text-align: left;
    }

    .token_sold span {
        width: 44%;
        text-align: right;
    }

    .token_sold {
        flex-wrap: wrap;
    }

    .box button.btn.btn_primary {
        text-align: center;
        display: flex;
    }

    .box h3 {
        text-align: center;
    }

    .arrow_flex h3 {
        text-align: left;
    }

    .upload_document {
        width: 100%;
    }
}