@import "../../variable.scss";

.blogListing_sc {
    padding-top: 20px;

    .head {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 0 30px;

        h1 {
            color: $c_white;
            font-size: 36px;
            font-weight: 600;

            @media (max-width: 1399.98px) {
                font-size: 30px;
            }

            @media (max-width: 1199.98px) {
                font-size: 28px;
            }

            @media (max-width: 991.98px) {
                font-size: 25px;
            }

            @media (max-width: 600px) {
                font-size: 22px;
            }
        }
    }

    .blogs_list {
        .single {
            gap: 0px;
            padding: 20px;
            width: calc(100% / 3 - var(--gap_x));
            flex-direction: column;
            background-color: #373737;
            box-shadow: 0px 1px 2px 0px rgba(44, 54, 104, 0.05);
            position: relative;

            @media (max-width: 1199.98px) {
                border: none;
                justify-content: flex-start;
                padding: 10px;
                border-radius: 0;
            }

            @media (max-width: 991.98px) {
                width: calc(50% - var(--gap_x));
            }

            @media (max-width: 600px) {
                width: calc(100% - var(--gap_x));
            }

            >a {
                position: absolute;
                inset: 0;
                z-index: 1;
            }

            &:not(:last-child) {
                padding: 20px;
                margin-bottom: 0;
                border: none;

                @media (max-width: 1199.98px) {
                    padding: 10px;
                }
            }

            figure {
                width: 100%;
                min-width: 100%;
                height: 170px;
                border-radius: 0;
                margin-bottom: 20px;
            }

            .blog_info {
                @media (max-width: 1199.98px) {
                    text-align: left;
                }

                p.rounded {
                    height: 26px;
                    padding: 2px 10px;
                    font-size: 12px;
                }

                h3 {
                    font-size: 18px;
                    margin: 15px 0 10px;

                    @media (max-width: 1199.98px) {
                        font-size: 16px;
                    }
                }

                p {
                    font-size: 14px;
                    word-wrap: break-word;
                }

                .btn_link {
                    font-size: 14px;
                    display: inline-flex;
                    margin-top: 20px;
                }
            }
        }
    }
}

.blogDetail_sc {
    .inner {
        max-width: 750px;
        width: 100%;
        margin: 0 auto;
    }

    .blog_figure {
        width: 100%;
        border-radius: 0;
        margin-bottom: 20px;

        img {
            width: 100%;
        }
    }

    p.rounded {
        display: inline-flex;
        align-items: center;
        height: 26px;
        padding: 2px 10px;
        font-size: 12px;
        border: 1px solid #828282;
        border-radius: 50px;
        font-weight: 500;
        color: #CDFF00;
    }

    h1 {
        font-size: 26px;
        margin: 20px 0 15px;
        font-weight: 600;
        line-height: 1.4;
        color: $c_white;

        @media (max-width:991.98px) {
            font-size: 24px;
        }

        @media (max-width: 767.98px) {
            font-size: 22px;
        }

        @media (max-width: 600px) {
            font-size: 20px;

        }
    }

    .blog_desc {
        p {
            &:not(:last-child) {
                margin-bottom: 12px;
            }
        }
    }
}