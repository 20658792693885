// variable start here
$c_primary: #252525;
$c_secondary: #CDFF00;
$c_bg: #f8fafc;
$c_body: #d1d3d8;
$c_info: #00a1ff;
$c_info: #dc6803;
$c_blue: #414de1;
$c_border: #eef2f6;
$grey: #9aa4b2;
$c_danger: #d92d20;
$c_success: #079455;
$c_active: #00EF00;
$c_darkgray: #373737;
$body_bg: #131313;
$c_black: #000;
$c_white: #fff;
$c_heading: #d1d3d8;
$f_body: "Bw Modelica";
$f_wains: "Wainscoted";
$transition: all 0.3s ease-in-out 0s;
$shadow: 0px 14px 64px -4px rgba(45, 115, 227, 0.12),
  0px 8px 22px -6px rgba(45, 115, 227, 0.12);
// veriable end