@import "../../variable.scss";

.home_page {

    h1,
    h2 {
        font-weight: 400;
        font-family: $f_wains;
    }

    h2 {
        padding-bottom: 25px;
        position: relative;

        @media (max-width: 991.98px) {
            text-align: center;
        }

        @media (max-width: 600px) {
            padding-bottom: 0px;
        }

        &:not(:last-child) {
            margin-bottom: 30px;

            @media (max-width: 600px) {
                margin-bottom: 20px;
            }
        }

        &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 100%;
            height: 3px;
            width: 425px;
            transform: translateY(-100%);
            background-color: $c_secondary;
            border-radius: 3px;

            @media (max-width: 991.98px) {
                left: 50%;
                transform: translateY(-100%) translateX(-50%);
            }

            @media (max-width: 600px) {
                display: none;
            }
        }
    }

    .text_center:has(>h2) {
        h2 {
            &::before {
                left: 50%;
                transform: translateY(-100%) translateX(-50%);
            }
        }
    }

    p {
        color: #B7BBC5;
        word-wrap: break-word;

        &:not(:last-child) {
            margin-bottom: 20px;
        }
    }
}

.hero {
    &_sc {
        background-image: url("../../../public/images/hero_bg.png");
        background-repeat: no-repeat;
        background-position: right bottom;
        padding-block: 100px 150px;
        position: relative;

        @media (max-width: 1199.98px) {
            background-size: 500px;
            padding-block: 60px 80px;
        }

        @media (max-width: 991.98px) {
            background: none;
        }

        @media (max-width: 600px) {
            padding-block: 50px 50px;
        }

        &::before {
            content: "";
            position: absolute;
            left: -7%;
            top: -28%;
            width: 536px;
            height: 536px;
            border-radius: 50%;
            background-color: $c_secondary;
            opacity: 0.07;
            z-index: 100;
            pointer-events: none;

            @media (max-width: 991.98px) {
                width: 350px;
                height: 350px;
            }

            @media (max-width: 767.98px) {
                left: -34%;
            }

            @media (max-width: 600px) {
                display: none;
            }

        }
    }

    &_left {
        max-width: 690px;

        @media (max-width: 1199.98px) {
            max-width: 500px;
        }

        @media (max-width: 991.98px) {
            max-width: 100%;
        }

        h1 {
            font-size: 69px;
            margin-bottom: 20px;
            line-height: 1.1;

            @media (max-width: 1399.98px) {
                font-size: 60px;
            }

            @media (max-width: 1199.98px) {
                font-size: 50px;
            }

            @media (max-width: 991.98px) {
                font-size: 50px;
                text-align: center;
            }

            @media (max-width: 767.98px) {
                font-size: 40px;
            }

            @media (max-width: 767.98px) {
                font-size: 30px;
            }

            span {
                font-size: 85px;
                color: $c_secondary;
                display: block;
                position: relative;
                font-weight: 400;
                z-index: 1;

                @media (max-width: 1399.98px) {
                    font-size: 80px;
                }

                @media (max-width: 1199.98px) {
                    font-size: 65px;
                    margin-top: 5px;
                }

                @media (max-width: 991.98px) {
                    font-size: 60px;
                }

                @media (max-width: 767.98px) {
                    font-size: 60px;
                }

                @media (max-width: 600px) {
                    font-size: 50px;
                }

                &::after {
                    content: attr(title);
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translate(0, -50%);
                    font-size: 149px;
                    -webkit-text-fill-color: transparent;
                    -webkit-text-stroke-width: 1px;
                    -webkit-text-stroke-color: rgba(112, 112, 112, 0.25);
                    z-index: -1;

                    @media (max-width: 1399.98px) {
                        font-size: 120px;
                    }

                    @media (max-width: 1199.98px) {
                        font-size: 95px;
                    }

                    @media (max-width: 991.98px) {
                        display: none;
                    }
                }
            }
        }

        p {
            max-width: 430px;

            @media (max-width: 1399.98px) {
                max-width: 500px;
            }

            @media (max-width: 1199.98px) {
                max-width: 400px;
            }

            @media (max-width: 991.98px) {
                max-width: 500px;
                text-align: center;
                margin-inline: auto;
            }
        }

        .form {
            margin-top: 40px;
            max-width: 390px;

            @media (max-width: 1399.98px) {
                max-width: 350px;
            }

            @media (max-width: 991.98px) {
                margin: 0 auto;
            }

            .form_control {
                margin: 0;
                position: relative;

                input {
                    border-radius: 30px !important;
                    padding-left: 25px;
                    font-size: 16px;
                    border-color: #9D9D9D;

                    @media (max-width: 1399.98px) {
                        font-size: 14px;
                        font-weight: 500;
                    }

                    &:focus {
                        border-color: $c_secondary;
                    }
                }
            }
        }
    }
}

.marquee {
    &_sc {
        width: 100%;
        height: 80px;
        display: flex;
        align-items: center;
        overflow: hidden;
        white-space: nowrap;
        background-color: $c_secondary;

        @media (max-width: 1199.98px) {
            height: 70px;
        }
    }

    &_content {
        display: inline-flex;
        align-items: center;
        animation: marquee 25s linear infinite;
        font-size: 16px;
        color: #131313;
        font-weight: 700;

        @media (max-width: 1199.98px) {
            font-size: 14px;
        }

        span {
            display: inline-flex;
            align-items: center;

            &:not(:last-child) {
                &::after {
                    content: "";
                    width: 10px;
                    height: 10px;
                    display: inline-flex;
                    border-radius: 50%;
                    background-color: #131313;
                    margin: 0 15px;

                    @media (max-width: 1199.98px) {
                        width: 6px;
                        height: 6px;
                    }
                }
            }
        }
    }
}

@keyframes marquee {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(-100%);
    }
}

.about_sc {
    .gap_p {
        @media (max-width: 1199.98px) {
            --gap_y: 50px;
            flex-direction: column;
        }
    }

    .left_sc {
        width: 58%;

        @media (max-width: 1199.98px) {
            width: 100%;
            text-align: center;
        }

        h2 {
            &::before {
                left: 50%;
                transform: translateY(-100%) translateX(-50%);
            }
        }
    }

    .right_sc {
        width: 42%;

        @media (max-width: 991.98px) {
            width: 50%;
        }

        @media (max-width: 600px) {
            width: 80%;
        }

        figure {
            position: relative;

            &::before {
                content: "";
                background-image: url("../../../public/images/vector_01.png");
                background-repeat: no-repeat;
                background-size: cover;
                width: 121px;
                height: 94px;
                position: absolute;
                bottom: -20px;
                left: -40px;
                z-index: 1;

                @media (max-width: 600px) {
                    width: 97px;
                    height: 85px;
                    bottom: -20px;
                    left: -26px;
                    background-size: contain;
                }
            }

            img {
                width: 100%;
                mix-blend-mode: luminosity;
            }
        }
    }
}

.newEra_sc {
    .left_sc {
        width: 45%;

        @media (max-width: 1199.98px) {
            width: 100%;
            text-align: center;
            margin-top: 0px;
        }

        h2 {
            &::before {
                @media (max-width: 1199.98px) {
                    left: 50%;
                    transform: translateY(-100%) translateX(-50%);
                }
            }
        }
    }

    .right_sc {
        width: 55%;
        position: relative;

        @media (max-width: 1199.98px) {
            width: 100%;
        }

        &::before {
            content: "";
            width: 190px;
            height: 190px;
            border-radius: 50%;
            background-color: $c_secondary;
            position: absolute;
            right: -22px;
            bottom: -60px;
            opacity: 0.15;
            z-index: -1;
            pointer-events: none;

            @media (max-width: 1199.98px) {
                display: none;
            }
        }

        ul {
            --gap_x: 20px;
            --gap_y: 20px;

            @media (max-width: 767.98px) {
                --gap_x: 15px;
                --gap_y: 15px;
            }

            li {
                width: calc(100% / 2 - var(--gap_y));
                padding: 25px 20px;
                background-color: #252525;
                border: 1px solid #707070;
                border-radius: 10px;
                transition: $transition;

                @media (max-width: 600px) {
                    width: calc(100% / 1 - var(--gap_y));
                    text-align: center;
                }

                figure {
                    height: 42px;

                    img {
                        height: 100%;
                    }
                }

                h3 {
                    color: #EEE;
                    font-size: 20px;
                    font-weight: 600;
                    margin: 20px 0 12px;

                    @media (max-width: 991.98px) {
                        font-size: 18px;
                        margin: 20px 0 10px;
                    }
                }

                p {
                    color: $c_white;
                    font-size: 14px;
                    line-height: 1.6;
                    font-weight: 300;
                }

                &:not(:hover) {
                    figure {
                        img {
                            filter: brightness(0) invert(100%) sepia(42%) saturate(757%) hue-rotate(177deg) brightness(91%) contrast(85%);
                        }
                    }
                }

                &:hover {
                    background-color: #131313;
                    border-color: $c_secondary;
                    transform: translateY(-5px);
                }
            }
        }
    }
}

.propertiesList_sc {
    background-image: url("../../../public/images/ellipse-vector.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;
    position: relative;
    z-index: 1;

    &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 200px;
        background-image: linear-gradient(to top, #131313, transparent);
        z-index: -1;
    }
}

.faq_sc {
    .accordion {
        &_item {
            border: none;
            box-shadow: none;
            background-color: transparent;
            max-width: 1010px;
            margin-inline: auto;

            &::before {
                display: none;
            }

            &.Mui-expanded {
                margin-block: 0;
                margin-inline: auto;

                .accordion_title {
                    &::after {
                        background-image: url("../../../public/images/minus-circle.svg");
                    }
                }
            }

            &:not(:last-child) {
                border-bottom: 1px solid #707070;
            }
        }

        &_title {
            padding: 20px 0;
            gap: 20px;
            min-height: 64px;

            @media (max-width: 991.98px) {
                padding: 15px 0;
            }

            &::after {
                content: "";
                width: 34px;
                height: 34px;
                min-width: 34px;
                background-image: url("../../../public/images/add-circle.svg");
                background-size: cover;
                background-repeat: no-repeat;

                @media (max-width: 991.98px) {
                    width: 25px;
                    height: 25px;
                    min-width: 25px;
                }
            }

            .MuiAccordionSummary-content {
                margin: 0;
                font-size: 28px;
                font-weight: 400;
                color: $c_white;
                transition: $transition;

                @media (max-width: 1399.98px) {
                    font-size: 25px;
                }

                @media (max-width: 1199.98px) {
                    font-size: 20px;
                }

                @media (max-width: 991.98px) {
                    font-size: 18px;
                }

                @media (max-width: 600px) {
                    font-size: 16px;
                }

            }

            &:hover {
                .MuiAccordionSummary-content {
                    color: $c_secondary;
                }
            }
        }

        &_content {
            padding: 0 0 25px;
        }
    }
}

.news_sc {
    background-color: #252525;
    position: relative;
    overflow: hidden;
    z-index: 1;

    &::before {
        content: "";
        position: absolute;
        right: -120px;
        top: 50px;
        width: 400px;
        height: 400px;
        border-radius: 50%;
        background-color: $c_secondary;
        filter: blur(86.5px);
        opacity: 0.15;
        z-index: -1;
        pointer-events: none;
    }
}

.cta_sc {
    background: linear-gradient(to bottom, #252525 50%, #131313 50%);

    .inner {
        background-image: url("../../../public/images/cta_bg.svg");
        background-position: center bottom;
        background-repeat: no-repeat;
        background-color: $c_secondary;
        border-radius: 10px;
        min-height: 330px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 50px 70px;
        gap: 30px;

        @media (max-width: 1399.98px) {
            min-height: 1px;
        }

        @media (max-width: 991.98px) {
            min-height: auto;
            flex-direction: column-reverse;
            padding: 40px;
            gap: 20px;
        }

        @media (max-width: 600px) {
            padding: 40px 20px;
            text-align: center;
        }

        .hd_1 {
            max-width: 700px;
        }

        h2 {
            padding: 0;
            color: #131313;
            margin-bottom: 25px;

            @media (max-width: 991.98px) {
                margin-bottom: 10px;
            }

            &::before {
                display: none;
            }
        }

        p {
            color: #131313;
            font-weight: 500;
        }
    }
}

.property {
    &_swiper {
        padding: 0 60px !important;
        margin: 0 -60px !important;

        @media (max-width: 1199.98px) {
            padding: 0 0 65px 0 !important;
            margin: 0 !important;
        }

        @media (max-width: 600px) {
            padding: 0 0 50px 0 !important;
        }

        &::before,
        &::after {
            content: "";
            position: absolute;
            top: 0;
            height: 100%;
            width: 60px;
            z-index: 2;
            background-color: #131313;

            @media (max-width: 1199.98px) {
                display: none;
            }
        }

        &::before {
            left: 0;
        }

        &::after {
            right: 0;
        }

        .single_slide {
            cursor: pointer;
            text-align: left;

            figure {
                width: 100%;
                height: 640px;
                margin-bottom: 20px;
                position: relative;

                @media (max-width: 1399.98px) {
                    height: 500px;
                }

                @media (max-width: 991.98px) {
                    height: 400px;
                }

                @media (max-width: 600px) {
                    height: 350px;
                }

                img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                    object-position: center;
                    opacity: 0.7;
                }

                figcaption {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    padding: 25px;
                    font-size: 18px;
                    color: $c_white;
                    writing-mode: vertical-lr;
                    transform: rotate(180deg);
                    transition: $transition;
                    z-index: 1;
                }

                .slide_overlay {
                    position: absolute;
                    inset: 0;
                    display: flex;
                    align-items: flex-end;
                    background: linear-gradient(180deg, rgba(19, 19, 19, 0.00) 0%, #000 100%);
                    transition: $transition;
                    overflow: hidden;
                    opacity: 0;
                    z-index: 2;

                    h3 {
                        font-size: 69px;
                        font-weight: 400;
                        color: $c_secondary;
                        font-family: $f_wains;
                        padding: 25px;
                        width: 100%;
                        transform: translate(0, 100%);
                        transition: $transition;
                        text-transform: capitalize;

                        @media (max-width: 1399.98px) {
                            font-size: 60px;
                        }

                        @media (max-width: 1199.98px) {
                            font-size: 50px;
                        }

                        @media (max-width: 991.98px) {
                            font-size: 40px;
                            padding: 20px;
                        }

                        @media (max-width: 767.98px) {
                            font-size: 40px;
                        }
                    }
                }
            }
        }

        .swiper-slide {
            flex-shrink: 0;
            width: calc(100% / 4) !important;
            transition: $transition;

            @media (max-width: 600px) {
                width: 100% !important;

                figure {
                    img {
                        opacity: 1;
                    }

                    figcaption {
                        opacity: 0;
                    }

                    .slide_overlay {
                        opacity: 1;

                        h3 {
                            transform: none;
                        }
                    }
                }
            }

            &-active {
                +.swiper-slide {
                    width: calc(100% / 2) !important;

                    @media (max-width: 600px) {
                        width: 100% !important;
                    }

                    figure {
                        img {
                            opacity: 1;
                        }

                        figcaption {
                            opacity: 0;
                        }

                        .slide_overlay {
                            opacity: 1;

                            h3 {
                                transform: none;
                            }
                        }
                    }
                }
            }
        }

        .swiper-button {

            &-prev,
            &-next {
                position: absolute;
                top: calc(50% - 0px);
                transform: translate(0, -50%);
                margin: 0;
                width: 70px;
                height: 70px;
                padding: 5px;
                border-radius: 50%;
                border: 1px dashed $c_white;
                display: flex;
                align-items: center;
                justify-content: center;
                color: $c_white;
                z-index: 3;

                @media (max-width: 1199.98px) {
                    width: 60px;
                    height: 60px;
                    top: auto;
                    bottom: 0;
                }

                @media (max-width: 600px) {
                    width: 50px;
                    height: 50px;
                }

                &::before {
                    content: "";
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    width: calc(100% - 10px);
                    height: calc(100% - 10px);
                    background-color: #27262B;
                    transform: translate(-50%, -50%);
                    border-radius: 50%;
                    transition: $transition;
                    z-index: -1;
                }

                &::after {
                    font-size: 18px !important;
                    transform: scale(-1);
                }

                &:hover {
                    &::before {
                        box-shadow: 0 0 40px -2px rgba(205, 255, 0, 0.5);
                    }
                }
            }

            &-prev {
                right: auto !important;
                left: 60px !important;
                transform: translate(-50%, -50%);

                @media (max-width: 1199.98px) {
                    left: calc(50% - 50px) !important;
                    transform: translate(-50%, 0);
                }

                @media (max-width: 767.98px) {
                    left: calc(50% - 40px) !important;
                }
            }

            &-next {
                left: auto !important;
                right: 60px !important;
                transform: translate(50%, -50%);

                @media (max-width: 1199.98px) {
                    right: auto !important;
                    left: calc(50% + 50px) !important;
                    transform: translate(-50%, 0);
                }

                @media (max-width: 767.98px) {
                    left: calc(50% + 40px) !important;
                }
            }
        }
    }
}

//search div
.product_srch {
    background: #373737;
    padding: 10px 0;
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    margin-top: 5px;
    min-height: 90px;
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    max-height: 500px;
    overflow: auto;
    z-index: 1;

    ul {
        .main_li {
            display: flex;
            align-items: center;
            gap: 15px;
            margin-bottom: 0;
            padding: 5px;
            transition: $transition;
            border-radius: 5px;
            cursor: pointer;

            &:not(:last-child) {
                margin-bottom: 10px;
            }

            &:hover {
                background: rgba(255, 255, 255, 0.1);
            }

            .li_left {
                figure {
                    width: 50px;
                    height: 50px;
                    border-radius: 5px;
                    overflow: hidden;
                    border: 1px solid rgba(0, 0, 0, 0.1);

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: center;
                    }
                }
            }

            .li_ryt {
                width: 100%;

                .stock_flex {
                    display: flex;
                    flex-direction: column;
                    width: 100%;

                    h3 {
                        font-size: 16px;
                        font-weight: 600;
                        color: $c_white;

                        @media screen and (max-width: 767.98px) {
                            font-size: 14px;
                        }

                        &::before {
                            display: none;
                        }
                    }

                    p {
                        font-size: 13px;
                        color: #888;
                        width: 100%;
                        max-width: 100%;
                        text-align: left;
                    }
                }
            }
        }
    }

    h3.no_record {
        width: 100%;
        height: 100%;
        min-height: 90px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    h3 {
        font-size: 14px;
        color: #fff;
        font-family: "Bw Modelica";

        &::before {
            content: "";
            position: absolute;
            left: 3px;
            height: 3px;
            width: 98%;
            transform: translateY(-100%);
            background-color: $c_secondary;
            border-radius: 3px;
            bottom: 6px;
        }
    }
}

@media (max-width: 1199.98px) {

    .blog_sc .hd_1 h2,
    .news_sc .hd_1 h2 {
        text-align: center;
    }

    .blog_sc h2:before,
    .news_sc h2:before {
        left: 50%;
        transform: translateY(-100%) translateX(-50%);
    }
}