@import "../../variable.scss";

.auth_page {
    .left_side {
        width: 55%;
        padding-top: 20px;
        height: calc(100vh - 121px);
        position: sticky;
        top: 121px;
        display: flex;
        flex-direction: column;

        @media (max-width: 1199.98px) {

            display: none;
        }

        h1 {
            color: $c_white;
            font-weight: 900;
            letter-spacing: 1.68px;
            padding-left: 30px;
            margin-bottom: 50px;

            @media (max-width: 1399.98px) {
                font-weight: 700;
            }

            @media screen and (max-width: 1399.98px) {
                margin-bottom: 30px;
            }
        }

        video {
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: center top;
            flex-grow: 1;
        }

        figure {
            height: 100%;
            width: 100%;
            position: relative;
            overflow: hidden;

            img {
                position: absolute;
                left: 0;
                bottom: 0;
                height: 100%;
                min-height: 100%;
                width: auto;
                object-fit: contain;
                object-position: center bottom;
                animation: move_animation 100s linear infinite;
                animation-direction: alternate;

                @media screen and (max-width: 1399.98px) {
                    bottom: auto;
                    height: 150%;
                    min-height: 150%;
                }

                &:last-child {
                    height: 70%;
                    min-height: 70%;
                    margin-top: auto;
                    bottom: -30%;

                    @media screen and (max-width: 1399.98px) {
                        bottom: -35%;
                    }
                }
            }
        }

        @keyframes move_animation {
            0% {
                transform: translateX(0);
            }

            50% {
                transform: translateX(calc(-100% + 50vw));
            }

            100% {
                transform: translateX(0);
            }
        }
    }

    .right_side {
        width: 45%;
        padding-block: 30px;

        @media (max-width: 1199.98px) {
            width: 55%;
            padding-block: 50px;
        }

        @media (max-width: 991.98px) {
            width: 70%;
        }

        @media (max-width: 767.98px) {
            width: 80%;
        }

        @media (max-width: 600px) {
            width: 100%;
        }


        &.small_width {
            width: 100%;
            max-width: 570px;
            padding-block: 0;
            margin-inline: auto;
        }

        .login_line {
            font-size: 16px;
            text-align: center;
            margin-top: 20px;

            @media (max-width: 1199.98px) {
                font-size: 14px;
                margin-top: 25px;
            }

            span {
                color: $c_secondary;
                cursor: pointer;
            }
        }

        h2 {
            color: $c_white;
            margin-bottom: 50px;
            font-size: 22px;

            @media (max-width: 1199.98px) {
                text-align: center;
                margin-bottom: 30px;
            }
        }

        h3 {
            color: $c_white;
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 24px;

            @media (max-width: 600px) {
                text-align: center;
                margin-bottom: 24px;
                margin-top: 40px;
            }
        }

        .form_control {
            .gap_p {
                // align-items: center;
                justify-content: space-between;
            }

            .upload_image,
            .img_cont {
                width: 88px;
                height: 88px;
                position: relative;
                border-radius: 50%;
                background-color: $c_darkgray;
                display: flex;
                align-items: center;
                justify-content: center;

                @media (max-width: 1199.98px) {
                    margin: 0 auto;
                }

                figure {
                    border-radius: 50%;
                    height: 100%;
                    overflow: hidden;
                    width: 100%;
                    border: 1px solid #fff;
                    padding: 6px;

                    img {
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                        object-position: center;
                        border-radius: 100%;
                    }
                }
            }

            .note {
                margin-top: 5px;
                color: $c_secondary;
                font-size: 14px;

                a {
                    color: $c_white;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }

        .form_btn {
            .btn {
                width: 100%;
            }
        }

        .upload_document {
            .inner {
                width: 100%;
                height: 170px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                background-color: $c_darkgray;
                border-radius: 10px;
                position: relative;

                >span {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    font-size: 14px;
                    font-weight: 300;
                    color: $c_white;
                    gap: 20px;

                    img {
                        height: 50px;
                    }
                }
            }

            &.v2 {
                .inner {
                    border: 1px solid #EEF2F6;

                    svg {
                        font-size: 40px;
                    }
                }
            }

            figure {
                height: 100%;
                width: 100%;
                border-radius: 10px;
                overflow: hidden;

                img {
                    height: 100%;
                    width: 100%;
                    object-fit: contain;
                    object-position: center;
                }
            }

            input[type="file"] {
                position: absolute;
                inset: 0;
                padding: 0;
                height: 100%;
                width: 100%;
                opacity: 0;
                cursor: pointer;
                text-indent: -1000px;
            }
        }

        .modalClick {
            font-size: 18px;
            font-weight: 600;
            color: $c_white;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid $c_white;
            padding-bottom: 10px;
            cursor: pointer;

            @media (max-width: 767.98px) {
                font-size: 16px;
            }

            svg {
                font-size: 20px;
            }
        }

        .stake_info {
            padding-top: 15px;

            li {
                color: $c_white;
                font-size: 16px;
                font-weight: 400;
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 30px;

                &:not(:last-child) {
                    padding-bottom: 15px;
                    margin-bottom: 15px;
                    border-bottom: 1px solid #EEF2F650;
                }

                strong {
                    font-weight: 700;
                }
            }
        }
    }
}

.modal {
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
    height: 100%;

    .MuiBackdrop-root {
        background-color: rgba(0, 0, 0, 0.7);
    }

    .modalBox_body {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px 15px;
        width: 100%;
        background: transparent;
        position: relative;
        z-index: 1;
        min-height: 100%;

        >.MuiBox-root {
            padding: 25px;
            margin: 0 auto;
            max-width: 570px;
            width: 100%;
            border-radius: 30px;
            background: #252525;
            box-shadow: 0px 8px 16px -6px rgba(45, 115, 227, 0.08), 0px 6px 8px -6px rgba(45, 115, 227, 0.12);

            @media (max-width: 767.98px) {
                padding: 30px 15px;
                border-radius: 10px;
            }
        }
    }
}

.form_modal {
    .modalBox_body {
        .flex_head {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 30px;

            @media (max-width: 767.98px) {
                margin-bottom: 20px;
            }

            h2 {
                font-size: 24px;
                font-weight: 600;
                color: $c_white;
                padding-bottom: 5px;
                border-bottom: 1px solid $c_white;

                @media (max-width: 767.98px) {
                    font-size: 18px;
                }
            }

            .close_btn {
                min-width: 1px;
                width: auto;
                padding: 0;
                color: $c_white;
                transition: $transition;

                &:hover {
                    color: $c_secondary;
                }
            }
        }

        .value_list {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 12px;
            margin-bottom: 12px;

            &:empty {
                display: none;
            }

            li {
                border-radius: 8px;
                border: 1px solid #EEF2F6;
                background: #252525;
                min-height: 56px;
                padding: 10px 15px;
                word-break: break-all;
                color: #9AA4B2;
                font-size: 14px;
                font-weight: 500;
                display: inline-flex;
                align-items: center;
                position: relative;

                @media screen and (max-width: 767.98px) {
                    min-height: 50px;
                    border-radius: 5px;
                }

                svg {
                    position: absolute;
                    right: 0;
                    top: 0;
                    width: 18px;
                    height: 18px;
                    border-radius: 50%;
                    background-color: #ff0000;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    color: $c_white;
                    padding: 2px;
                    transform: translate(50%, -50%);

                    @media screen and (max-width: 767.98px) {
                        width: 16px;
                        height: 16px;
                    }
                }
            }
        }

        .flex {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 15px;

            @media (max-width: 767.98px) {
                gap: 5px;
            }

            >svg {
                width: 56px;
                padding: 0;
                border-radius: 8px;
                border: 1px solid #EEF2F6;
                background: #252525;
                min-height: 56px;
                color: #9AA4B2;
                font-size: 14px;
                font-weight: 500;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                padding: 14px;
                cursor: pointer;

                @media screen and (max-width: 767.98px) {
                    width: 50px;
                    min-height: 50px;
                    border-radius: 5px;
                }
            }
        }

        .profit_detail {
            background: #131313;
            border-radius: 10px;
            padding: 10px 15px;

            &:not(:last-child) {
                margin-bottom: 10px;
            }
        }

        .flex_label {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 15px;

            label {
                color: $c_white;
                font-size: 16px;
                font-weight: 700;
                margin-bottom: 0;
            }

            .add {
                gap: 5px;
                cursor: pointer;
                font-size: 12px;
                font-weight: 500;
                color: $c_secondary;
                display: inline-flex;
                align-items: center;
                border-radius: 40px;
                background: rgba(255, 255, 255, 0.09);
                padding: 4px 14px;

                svg {
                    font-size: 18px;
                }
            }
        }

        .form_control {
            @media (max-width: 767.98px) {
                margin-bottom: 15px;
            }

            &+h6 {
                color: $c_white;
                font-size: 20px;
                font-weight: 600;
                margin-bottom: 15px;
            }
        }

        .form_btn {
            .btn {
                width: 100%;
            }
        }
    }
}

.success_modal {
    .modalBox_body {
        .close_btn {
            position: absolute;
            right: 20px;
            top: 20px;
            min-width: 1px;
            width: auto;
            padding: 0;
            color: $c_white;
            transition: $transition;

            &:hover {
                color: $c_secondary;
            }
        }

        >.MuiBox-root {
            padding: 70px 125px;
            text-align: center;
            position: relative;

            figure {
                height: 269px;

                img {
                    height: 100%;
                }
            }

            h2 {
                color: $c_white;
                font-size: 30px;
                font-weight: 600;
                margin-block: 25px 35px;
            }

            p {
                color: $c_white;
            }
        }
    }
}

.gallery_modal {
    .modalBox_body {
        .close_btn {
            position: absolute;
            right: 20px;
            top: 20px;
            min-width: 1px;
            width: auto;
            padding: 0;
            color: $c_white;
            transition: $transition;

            &:hover {
                color: $c_secondary;
            }
        }

        >.MuiBox-root {
            max-width: 80vw;

            @media screen and (max-width: 767.98px) {
                max-width: 100vw;
                padding: 10px;
                border-radius: 10px;
            }
        }

        .gallery_swiper {
            .swiper-wrapper {
                margin-bottom: 0;
            }

            .swiper_action {
                justify-content: center;
                margin-top: 20px;
            }

            .swiper-slide {
                figure {
                    height: calc(80vh - (40px + 50px + 42px));
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                        max-width: 100%;
                        max-height: 100%;
                    }

                    video {
                        max-height: 100%;
                        max-width: 100%;
                    }
                }
            }
        }
    }
}

.confirmation_modal {
    .modalBox_body {
        >.MuiBox-root {
            position: relative;
            padding: 60px 25px;
            text-align: center;

            @media screen and (max-width: 767.98px) {
                padding: 60px 15px;
            }
        }

        .close_btn {
            position: absolute;
            right: 20px;
            top: 20px;
            min-width: 1px;
            width: auto;
            padding: 0;
            color: $c_white;
            transition: $transition;

            @media screen and (max-width: 767.98px) {
                right: 10px;
                top: 10px;
            }

            &:hover {
                color: $c_secondary;
            }
        }

        h2 {
            color: $c_white;
            font-size: 20px;

            @media screen and (max-width: 767.98px) {
                font-size: 18px;
                line-height: 1.5;
            }
        }

        p {
            margin: 15px 0 30px;
        }

        .flexdiv {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 30px;

            .btn {
                font-size: 14px;
                text-transform: uppercase;

                &.btn_secondary {
                    font-weight: 700;
                    min-height: 45px;
                    min-width: 160px;

                    @media screen and (max-width: 767.98px) {
                        min-height: 50px;
                        min-width: 140px;
                    }
                }
            }

            .cancel_btn {
                font-size: 14px;
                padding: 0;
                text-transform: uppercase;
                color: #fff;
                background-color: transparent;
                font-family: $f_body;

                @media screen and (max-width: 767.98px) {
                    font-size: 13px;
                    font-weight: 500;
                }

                &:hover {
                    color: $c_secondary;
                }
            }
        }
    }
}