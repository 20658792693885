@import "../../variable.scss";

.transactions_sc {
    h2 {
        color: $c_white;
        font-size: 36px;
        font-weight: 700;
        margin-bottom: 30px;

        @media (max-width: 1399.98px) {
            font-size: 30px;
            margin-bottom: 20px;
        }

        @media (max-width: 1199.98px) {
            font-size: 28px;
        }

        @media (max-width: 991.98px) {
            font-size: 25px;
        }

        @media (max-width: 600px) {
            font-size: 22px;
        }
    }

    .transaction {
        &_box {
            margin-bottom: 40px;

            @media (max-width: 1399.98px) {
                margin-bottom: 50px;
            }

            .single {
                width: calc(100% / 2 - var(--gap_x));
                display: flex;
                align-items: center;
                gap: 30px;
                border-radius: 10px;
                padding: 40px 25px;
                background-color: #373737;

                @media (max-width: 1399.98px) {
                    gap: 20px;
                    padding: 30px 20px;
                }

                @media (max-width: 991.98px) {
                    flex-direction: column;
                    text-align: center;
                    gap: 10px;
                }

                @media (max-width: 767.98px) {
                    padding: 20px;
                }

                @media (max-width: 600px) {
                    width: calc(100% / 1 - var(--gap_x));
                }

                figure {
                    min-width: 96px;
                    max-width: 96px;

                    @media (max-width: 991.98px) {
                        min-width: 80px;
                        max-width: 80px;
                    }

                    img {
                        width: 100%;
                    }
                }

                h3 {
                    color: $c_white;
                    font-size: 36px;
                    font-weight: 700;
                    margin-bottom: 10px;

                    @media (max-width: 1399.98px) {
                        font-size: 30px;
                    }

                    @media (max-width: 1199.98px) {
                        font-size: 24px;
                    }

                    @media (max-width: 767.98px) {
                        font-size: 22px;
                    }
                }

                p {
                    color: $c_white;
                    font-size: 14px;
                }
            }
        }

        &_table {
            overflow: auto;

            table {
                :is(th, td) {
                    color: $c_white;
                    border: none;
                    text-align: left;
                    font-family: $f_body;
                    line-height: normal;
                    letter-spacing: normal;

                    @media (max-width: 767.98px) {
                        white-space: nowrap;
                    }

                    &:first-child {
                        padding-left: 0;
                    }

                    &:last-child {
                        padding-right: 0;
                    }
                }

                th {
                    font-size: 24px;
                    font-weight: 600;
                    padding: 20px;

                    @media (max-width: 1399.98px) {
                        font-size: 20px;
                        padding: 15px;
                    }

                    @media (max-width: 991.98px) {
                        font-size: 18px;
                    }

                    @media (max-width: 767.98px) {
                        font-size: 16px;
                    }
                }

                td {
                    font-size: 16px;
                    padding: 14px 20px;

                    @media (max-width: 1399.98px) {
                        font-size: 14px;
                        padding: 10px 15px;
                    }
                }

                thead {
                    th {
                        padding-top: 0;
                    }
                }

                tbody {
                    tr {
                        &:last-child {
                            td {
                                padding-bottom: 0;
                            }
                        }
                    }

                    .flex {
                        display: inline-flex;
                        align-items: center;
                        font-size: 16px;
                        gap: 15px;

                        figure {
                            width: 44px;
                            height: 44px;
                            border-radius: 50%;
                            overflow: hidden;

                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                                object-position: center;
                            }
                        }
                    }
                }
            }
        }
    }
}